import { ISearchCriteriaRequest } from "./interfaces/isearchcriteriarequest";
import { ConnectionUses } from "../../Enums/connectionUses";
import { OuterDiameterWallThicknessAndWeight } from "./outerDiameterWallThicknessAndWeight";
import { Evaluation } from "../../Enums/evaluation";
import { SortBy } from "../../Enums/sortBy";
import { UnitOfMeasure } from "../../Enums/unitOfMeasure";
export class SearchCriteriaRequest implements ISearchCriteriaRequest {
  outerDiameter: OuterDiameterWallThicknessAndWeight[];
  yield: number[];
  manufacturer: string;
  connectionName: string;
  urcDocumentNumber: string;
  isCRA: boolean;
  isCarbonSteel: boolean;
  isFullyEvaluated: boolean;
  isThreadedAndCoupled: boolean;
  isIntegralJoint: boolean;
  isSemiFlush: boolean;
  isFlushJoint: boolean;
  threadCompoundApplied: string;
  highTorqueOnly: boolean;
  connectionUse: Array<ConnectionUses>;
  evaluation: Evaluation[];
  active: boolean;
  inactive: boolean;
  sortBy: SortBy;
  unitOfMeasure: UnitOfMeasure;
}
