<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="showErrorMessage()">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    {{errorMessage}}
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="closeErrorMessage()">
      Close
    </button>
    <!-- end em-c-btn -->
  </div>
</div>
<!-- end em-c-alert -->
<!-- end em-c-alert -->
