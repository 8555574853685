import { Component, ViewChild, OnInit, NgModule, Input, EventEmitter, Output } from '@angular/core';
import { section } from 'src/app/common/models/section.model'
import { editLoad, load } from 'src/app/common/models/load.model'
import { IExpansionPanelItem, IExpansionPanelModel } from '../../../../common/models/components/expansionpanelmodel.interface';
import { Constants } from '../../../../common/constants/constant';
import { MainHeaderService } from '../../../../service/tcdb/mainheader.service';
import { LoadCaseService } from '../../../../service/tcdb/loadcaseservice';
import { isValidTextWithSomeSpecialCharacterRestrictions } from '../../../../common/utilities/utilities';


@Component({
  selector: 'app-section',
  templateUrl: './section-control.component.html',
  styleUrls: ['./section-control.component.css']
})

export class SectionComponent {
  @ViewChild('sectionname') sectionname;
  load = new load();
  section = new section();
  loadArray: load[] = [];
  @Input() sectionArray: section[] = [];
  isCollapsed: boolean[] = [];
  index: number;
  isToEdit: boolean = false;
  oldSectionName: string;
  sectionModalOpen = false;
  loadModalOpen = false;
  sectionErrorMessage = '';
  sectionConfirmationModalOpen = false;
  sectionToDelete: string = '';
  loadConfirmationModalOpen = false;
  loadToDelete: string = '';
  sectionIndexToEditOrDeleteLoad = 0;
  loadConfirmationText = Constants.LoadDeleteConfirmation;
  sectionConfirmationText = Constants.SectionDeleteConfirmation;
  sectionTitleConfirmationText = '';
  loadTitleConfirmationText = '';
  countOfLoads = 0;
  @Output() editLoadEvent = new EventEmitter<editLoad>();
  @Output() addLoadEvent = new EventEmitter<number>();
  @Output() sectionAdded = new EventEmitter();
  @Output() deleteLoadEvent = new EventEmitter<editLoad>();
  @Output() deleteSectionEvent = new EventEmitter();

  actionList = ['Edit', 'Duplicate', 'Delete'];
  expansionFilter: IExpansionPanelModel = {
    title: '', items: [], usecheckbox: false, sort: 0, enableSecondLevel: false, icon: 'more-vertical'
  };


  constructor(private _mainHeaderService: MainHeaderService, private _loadCaseService: LoadCaseService) {
    let items = [];
    this.actionList.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      items.push(obj);
    });
    this.expansionFilter.items = items;
    
  }

  collapse(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }

  openSectionModal() {
    this.sectionModalOpen = true;
    this.oldSectionName = '';
    this.isToEdit = false;
    this.sectionErrorMessage = '';
  }

  closeSectionModal() {
    this.sectionModalOpen = false;
  }

  closeLoadModal() {
    this.loadModalOpen = false;

  }
  /*boxval = '';*/
  getloadvalue(val: string) {
    this.closeLoadModal();
    this.addLoad(this.index);
  }

  createOrEditSection(name: string, isToEdit: boolean, oldName: string) {
    if (!name) {
      this.sectionErrorMessage = Constants.SectionNameCantBeEmpty;
    }
    else if (!isValidTextWithSomeSpecialCharacterRestrictions(name))
    {
      this.sectionErrorMessage = Constants.SpecialCharacterError;
    }
    else if (isToEdit && this.checkUniqueSectionName(name)) {
      this.editSection(oldName, name);
      this.closeSectionModal();
    }
    else if (!isToEdit && this.checkUniqueSectionName(name)) {
      this.addSection(name);
      this.closeSectionModal();
    }
    else
      this.sectionErrorMessage = Constants.SectionAlreadyExists.replace('{{sectionname}}', name);

  }

  addLoad(index: number) {
    this.addLoadEvent.emit(index);
  }

  addSection(name: string) {
    this.section = new section();
    this.section.name = name;
    this.sectionArray.push(this.section);
    this.setLoadCount();
    this.sectionAdded.emit();
    
  }
  editSection(oldName: string, newName: string) {
    let sectionToBeEdited = this.sectionArray.find(p => p.name.toString() === oldName);
    sectionToBeEdited.name = newName;
  }

  openSectionModalToEdit(name: string) {
    this.oldSectionName = name;
    this.isToEdit = true;
    this.sectionModalOpen = true;
    this.sectionErrorMessage = '';
  }

  onSectionActionClick(actionValue, name: string) {
    switch (actionValue) {
      case 'Edit':
        this.openSectionModalToEdit(name);
        break;
      case 'Duplicate':
        this.duplicateSectionAndLoad(name);
        break;
      case 'Delete':
        this.openSectionConfirmationModal(name);
        break;
      default:
    }
  }

  checkUniqueSectionName(sectionName: string) {
    let existingSection = this.sectionArray.find(s => s.name.toLowerCase().trim() === sectionName.toLowerCase().trim())
    return (typeof existingSection === 'undefined' || existingSection === null || existingSection.name === '')
  }

  deleteSectionAndLoad(isToDelete: boolean) {
    this.sectionConfirmationModalOpen = false;
    if (isToDelete) {
      this.sectionArray.forEach((item, index) => {
        if (item.name.toLowerCase() === this.sectionToDelete.toLowerCase()) {
          this.sectionArray.splice(index, 1);
        }
      });
      this.sectionToDelete = '';
      this.setLoadCount();
      this.deleteSectionEvent.emit();
    }
  }

  openSectionConfirmationModal(name: string) {
    this.sectionTitleConfirmationText = 'Delete ' + name;
    this.sectionConfirmationText = Constants.SectionDeleteConfirmation.replace("{{sectionname}}", name);
    this.sectionConfirmationModalOpen = true;
    this.sectionToDelete = name;
  }

  duplicateSectionAndLoad(sectionName: string) {
    let existingSection = this.sectionArray.find(s => s.name.toString().toLowerCase().trim() === sectionName.toLowerCase().trim())
    if (typeof existingSection != 'undefined' && existingSection != null && existingSection.name != '') {
      var suffix = this.checkandAddDuplicateSection(existingSection);
      this.addSection(existingSection.name + suffix);

      let duplicatedSection = this.sectionArray.find(s => s.name.toLowerCase().trim() === (existingSection.name + suffix).toLowerCase().trim());
      const existingSectionLoads = JSON.parse(JSON.stringify(existingSection.loadArray));
      existingSectionLoads.forEach((loadItem) => {
        const loadToBeAdded = JSON.parse(JSON.stringify(loadItem));
        loadToBeAdded.name = loadToBeAdded.name + suffix;
        duplicatedSection.loadArray.push(loadToBeAdded)
        this.setLoadCount();
      });
    }
  }

  checkandAddDuplicateSection(section: section) {
    let suffixNumber: number = 1;
    let checkDuplicateStatus: boolean = true;
    let prevSec, currentSec: string;
    prevSec = section.name;
    currentSec = section.name + "_copy";
    while (checkDuplicateStatus) {
      if (this.checkUniqueSectionName(prevSec) && this.checkUniqueSectionName(currentSec)) {
        checkDuplicateStatus = false;
        var suffix = prevSec.split(section.name);
        return suffix[1];
      }
      else {
        prevSec = currentSec;
        currentSec = section.name + "_copy" + " (" + suffixNumber.toString() + ")";
        suffixNumber++;
      }
    }
  }

  onLoadActionClick(actionValue, loadName: string, sectionIndex: number) {
    switch (actionValue) {
      case 'Edit':
        let loadToEdit = this.sectionArray[sectionIndex].loadArray.find(p => p.name.toLowerCase() == loadName.toLowerCase());
        this.editLoad(loadToEdit, sectionIndex);
        break;
      case 'Duplicate':
        this.duplicateLoad(loadName, sectionIndex);
        break;
      case 'Delete':
        this.openLoadConfirmationModal(sectionIndex, loadName);
        break;
      default:
    }
  }

  deleteLoad(isToDelete: boolean) {
    this.loadConfirmationModalOpen = false;
    if (isToDelete) {
      let loadToDelete = this.sectionArray[this.sectionIndexToEditOrDeleteLoad].loadArray.find(p => p.name.toLowerCase() == this.loadToDelete.toLowerCase());
      let deleteLoadObj: editLoad = { load: loadToDelete, sectionIndex: this.sectionIndexToEditOrDeleteLoad };
      this.deleteLoadEvent.emit(deleteLoadObj);
    }
    
  }

  editLoad(loadToEdit: load, sectionIndex: number) {
    let editLoadObj: editLoad = { load: loadToEdit, sectionIndex: sectionIndex };
    this.editLoadEvent.emit(editLoadObj);
  }

  openLoadConfirmationModal(sectionIndex: number, loadName: string) {
    this.loadTitleConfirmationText = 'Delete ' + loadName;
    this.loadConfirmationText = Constants.LoadDeleteConfirmation.replace("{{loadname}}", loadName);
    this.loadConfirmationModalOpen = true;
    this.loadToDelete = loadName;
    this.sectionIndexToEditOrDeleteLoad = sectionIndex;
  }

  duplicateLoad(loadName: string, sectionIndex: number) {
    let existingLoad = this.sectionArray[sectionIndex].loadArray.find(s => s.name.toString().toLowerCase().trim() === loadName.toLowerCase().trim())
    if (typeof existingLoad != 'undefined' && existingLoad != null && existingLoad.name != '') {
      let suffix = this.generateLoadSuffix(existingLoad.name, sectionIndex);
      let loadDuplicated: load = { name: existingLoad.name + suffix, comment: existingLoad.comment, pressureAndTensionList: existingLoad.pressureAndTensionList, sealabilityEnvelopes: existingLoad.sealabilityEnvelopes };
      this.sectionArray[sectionIndex].loadArray.push(loadDuplicated);
      this.setLoadCount();
    }
  }

  generateLoadSuffix(loadName: string, sectionIndex: number) {
    let suffixNumber: number = 1;
    let checkDuplicateStatus: boolean = true;
    let prevName, currentName: string;
    prevName = loadName;
    currentName = loadName + "_copy";
    while (checkDuplicateStatus) {
      if (this.checkUniqueLoadName(prevName, sectionIndex) && this.checkUniqueLoadName(currentName, sectionIndex)) {
        checkDuplicateStatus = false;
        var suffix = prevName.split(loadName);
        return suffix[1];
      }
      else {
        prevName = currentName;
        currentName = loadName + "_copy" + " (" + suffixNumber.toString() + ")";
        suffixNumber++;
      }
    }
  }

  checkUniqueLoadName(loadName: string, sectionIndex: number) {
    let existingLoad = this.sectionArray[sectionIndex].loadArray.find(s => s.name.toLowerCase().trim() === loadName.toLowerCase().trim())
    return (typeof existingLoad === 'undefined' || existingLoad === null || existingLoad.name === '')
  }

  setLoadCount() {
    let loadCount = 0;
    this.sectionArray.forEach(section => {
      loadCount += section.loadArray.length;
    })
    this._mainHeaderService.UpdateLoadCount(loadCount);
    this._loadCaseService.saveLoadsLocalStorage(this.sectionArray);
  }

}


