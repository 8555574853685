import { Component} from '@angular/core';
import { AlertErrorService } from '../../../../service/tcdb/alert-error.service';

@Component({
  selector: 'app-alert-error',
  templateUrl: './alert-error.component.html',
  styleUrls: ['./alert-error.component.css']
})
export class AlertErrorComponent{
  errorMessage = '';

  constructor(private _alertErrorService: AlertErrorService) {
    this._alertErrorService.errorMessage.subscribe((v) => {
      this.errorMessage = v;
    })
  }
  closeErrorMessage() {
    this.errorMessage = '';
    this._alertErrorService.errorMessage.next('');
  }
  showErrorMessage() {
    return this.errorMessage === '' ? false : true;
  }
}
