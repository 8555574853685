import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { GraphService } from 'src/app/service/microsoft-graph/graph.service';
import { environment } from '../../../../../../environments/environment';
import { AppConfigService } from '../../../../../service/app-config/app-config.service';
import { AppBackendInfoService } from '../../../../../service/app-info/app-backend-info.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalCustomNavigationClient } from '@azure/msal-angular';


@Component({
  selector: 'app-about',
  templateUrl: './about-app.component.html',
  styleUrls: ['./about-app.component.css']
})
export class AboutAppComponent implements OnInit {
  _environment = '';
  _clientEnvironment = '';
  _version = '';
  _apiEnvironment = '';
  _userFullName: string;
  _userPrincipalName: string;
  _photo: SafeUrl;
  _backendInfo = null;
  GraphService;
  constructor(private _msalService: MsalService, private _graphService: GraphService,
    private _appBackendInfoService: AppBackendInfoService, _config: AppConfigService) {
    this._environment = environment.name === null || environment.name === undefined ? 'Not Found' : environment.name;

    const account = this._msalService.instance.getAllAccounts()[0];
    this._photo = this._graphService.getUserPhoto();
    this._userFullName = account.name;
    this._userPrincipalName = account.username;
    _config.getSettings();
    localStorage.setItem('MenuSelected', '');
  }

  ngOnInit() {
    this._appBackendInfoService.getBasicInfo().subscribe((data) => {
      this._backendInfo = data;

      // set version if available
      this._version = data === null ? '' : data.appVersion;
      this._apiEnvironment = data === null ? '' : data.environmentName;
    });
    this._appBackendInfoService.getExtendedInfo().subscribe((data) => {
    })
  }
}
