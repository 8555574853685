export function deleteFromArray(item: any, array: any[]): void {
  const index = array.indexOf(item);
  if (index < 0) { return; }
  array.splice(index, 1);
}

export function replaceInArray(item: any, array: any[], replacement: any): void {
  const index = array.indexOf(item);
  if (index < 0) { return; }
  array.splice(index, 1, replacement);
}

export function getUniqueValues(array: any[]): any[] {
  return array.filter(onlyUnique);
}

function onlyUnique(value, index, self): boolean {
  return self.indexOf(value) === index;
}

export function moveItemToIndex(array: any[], item: any, index: number): void {
  const currentIndex = array.indexOf(item);
  if (currentIndex < 0) { return; }
  array.splice(currentIndex, 1);
  array.splice(index, 0, item);
}

export function range(start: number, end: number) {
  return Array.from({ length: (end - start) + 1 }, (_, i) => start + i);
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(parseFloat(str))
}
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function shallowCopy(objToCopy) {
  return { ...objToCopy };
}

export function deepCopy(objToCopy) {
  return JSON.parse(JSON.stringify(objToCopy));
}

export function roundTo(num, place) {
  return +(Math.round(Number(num + "e+" + place)) + "e-" + place);
}

export function alphabetArray() {
  return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
}

export function isValidTextWithSomeSpecialCharacterRestrictions(value) {
  var regex = /^[-.,_:/"\n\r\tA-Za-z0-9 ]+$/;
  if (!value)
    return true;
  var isValid = regex.test(value);
  return isValid;
}

export function removeNotAllowedSpecialCharacters(value): RemoveSpecialCharacters {
  var responseValue: RemoveSpecialCharacters = { value: '', removed: false };
  var regex = /[^\w\s-.,_:/"\n\r\t]/gi;
  var isValid = isValidTextWithSomeSpecialCharacterRestrictions(value);

  if (!isValid) {
    responseValue.value = value.replace(regex, "");
    responseValue.removed = true;
  }
  else {
    responseValue.value = value;
  }

  return responseValue;
}

export class RemoveSpecialCharacters {
  value: string;
  removed: boolean;
}


