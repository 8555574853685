import { Component, OnInit, Input } from '@angular/core';
import { ITableHeader } from '../../../../common/models/components/iTableHeader';
import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/iCandidateConnectionResult.interface';
import { CandidateConnectionResult } from '../../../../common/models/responses/candidateConnectionResult';

@Component({
  selector: 'app-details-tab-item',
  templateUrl: './details-tab-item.component.html',
  styleUrls: ['./details-tab-item.component.css']
})
export class DetailsTabItemComponent{
  @Input() detailsList: ICandidateConnectionResult = new CandidateConnectionResult();

}
