import { EndPoints } from './../app-config/endpoints';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppBackendInfo } from '../../common/models/config/app-backend-info';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AppBackendInfoService {

  public error: string;
  private endpointURL: string;

  constructor(private http: HttpClient, private router: Router, private appConfigService: AppConfigService) {
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.error = errorMessage;

    return throwError(errorMessage);
  }

  getBasicInfo() {
    this.error = '';
    this.endpointURL = `${EndPoints.API_BASE_URL}/diagnostics/ping`;
    return this.http.get<AppBackendInfo>(this.endpointURL);
  }

  getExtendedInfo() {
    this.error = '';
    this.endpointURL = `${EndPoints.API_BASE_URL}/diagnostics`;
    return this.http.get<AppBackendInfo>(this.endpointURL).pipe(catchError(this.handleError));
  }
}
