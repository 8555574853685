import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { IExpansionPanelModel } from '../../../../common/models/components/expansionpanelmodel.interface';

@Component({
  selector: 'app-expansion-panel-checkboxes',
  templateUrl: './expansion-panel-checkboxes.component.html',
  styleUrls: ['./expansion-panel-checkboxes.component.css']
})
export class ExpansionPanelCheckboxesComponent implements OnInit {
  @Input() expansionPanel: IExpansionPanelModel = { title: '', items: [], usecheckbox: false, sort: 0, enableSecondLevel: false, icon: '' };
  @Output() checkBoxEvent = new EventEmitter();

  public checkboxOptions = [];
  isClosed = true;
  forceCollapse = false;

  constructor() {
  }

  ngOnInit() {
    this.checkboxOptions = Array.from(this.expansionPanel.items);
  }

  onParentCheckboxChange(event: any) {
    this.expansionPanel.expansionCheckboxChecked = event.target.checked;
    this.expansionPanel.items.forEach(p => {
      p.checked = event.target.checked;
    });

    this.checkBoxEvent.emit(this.expansionPanel);
  }

  onCheckboxChange(event: any, value: string) {
    var checkboxValue = this.expansionPanel.items.find(obj => { return obj.value == value });
    checkboxValue.checked = event.target.checked;
    this.checkBoxEvent.emit(this.expansionPanel);
  }

  onExpansion() {
    this.forceCollapse = false;
    this.isClosed = !this.isClosed;

    if (this.expansionPanel.icon && !this.isClosed) {
      this.forceCollapse = false;
      this.isClosed = false;
    }
  }

  getElementId(item) {
    var elementId = this.expansionPanel.title.trim().replace(/\s/g, "") + ".";

    if (Number(item).toString() === 'NaN') {
      elementId += item.trim().replace(/\s/g, "")
    }
    else {
      elementId += item.toString();
    }
    elementId = elementId.toLowerCase();
    return elementId;
  }

}
