import { Component, EventEmitter, Input, Output } from '@angular/core';
import { range } from '../../../../common/utilities/utilities';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {

  @Input() numPages: number;

  private _startPage: number = 1;
  @Input() get startPage() { return this._startPage; }
  set startPage(val: number) {
    this._startPage = Number(val);
    if (this.currentPage < this.startPage) {
      this.currentPage = this.startPage;
    }
  }

  @Output() pageClick = new EventEmitter<number>();

  @Input() innerClass: string;
  @Input() innerStyle: string;

  private _currentPage: number = 1;
  @Input() get currentPage() { return this._currentPage; }
  set currentPage(val: number) {
    this._currentPage = Number(val);
    this.currentPageChange.emit(this._currentPage);
  }
  @Output() currentPageChange = new EventEmitter<number>();

  @Input() span: number = 3;

  get endPage(): number { return (Number(this.startPage) + Number(this.numPages)) - 1; }

  get currentRange(): number[] {
    let start = this.currentPage - Number(this.span);
    let end = this.currentPage + Number(this.span);
    while (start < this.startPage) {
      start++;
      if (end < this.endPage) end++;
    }
    while (end > this.endPage) {
      end--;
      if (start > this.startPage) start--;
    }

    return range(start, end);
  }

  onClick(index: number) {
    this.currentPage = index;
    this.pageClick.emit(index);
  }

  showArrow() {
    return this.numPages >= 7;
  }

  movePage(delta: number) {
    this.currentPage += delta;
    this.pageClick.emit(this.currentPage);
  }
}
