import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/icandidateConnectionResult.interface';
import { MsalService } from '@azure/msal-angular';
import { SearchCriteriaService } from '../../../../service/tcdb/searchcriteria.service';

@Component({
  selector: 'app-candidate-view-details-export-pdf',
  templateUrl: './candidate-view-details-export-pdf.component.html',
  styleUrls: ['./candidate-view-details-export-pdf.component.css']
})
export class CandidateViewDetailsExportPdfComponent implements OnInit {
  @Input() liquidChartToPDF;
  @Input() gasChartToPDF;
  @Input() structuralChartToPDF;
  @Input() candidateConnection: ICandidateConnectionResult;
  @Input() section_liquid;
  @Input() section_structure;
  @Input() section_gas;
  @Input() caseDescriptionPDF;
  @Input() sectionArrayList;

  userName = '';
  unitOfMeasure='';
  currentDateTime;
  unitOfPressure = '';
  unitOfTension = '';
  public sealTypesString:string = ""; 
  public sealLocationString:string = ""; 
  public shoulderLocationString:string = ""; 
  constructor(private _msalService: MsalService, private _searchCriteriaService: SearchCriteriaService) {
    const account = this._msalService.instance.getAllAccounts()[0];
    this.userName = account.name;
    this.currentDateTime = new Date().toLocaleString();
    this.unitOfMeasure = this._searchCriteriaService.getFilters()?.unitOfMeasure;
}

  ngOnInit(): void {
    this.getCombinedSealTypeString();
    this.getCombinedSealLocationString();
    this.getCombinedShoulderLocationString();    
  }
  getCombinedSealTypeString() {
    if (this.candidateConnection && this.candidateConnection.sealTypeTaperedThread && this.candidateConnection.sealTypeTaperedThread != "")
      this.sealTypesString += this.candidateConnection.sealTypeTaperedThread + '\n';
    else if (this.candidateConnection && this.candidateConnection.sealTypeMetal && this.candidateConnection.sealTypeMetal != "")
      this.sealTypesString += this.candidateConnection.sealTypeMetal + '\n';
    else if (this.candidateConnection && this.candidateConnection.sealTypeRing && this.candidateConnection.sealTypeRing != "")
      this.sealTypesString += this.candidateConnection.sealTypeRing + '\n';

  }
  tempSealibilityList = []
  getflattenedArray(data: any) {
    this.tempSealibilityList = [];
    const filteredSealabilityList = this.sectionArrayList.flatMap(x => x.loadArray.filter(x => x.name === data))
      .flatMap(x => x.sealabilityEnvelopes.filter(m => m.checked)).map(u => u.name);
    filteredSealabilityList.forEach(x => {
      if (x === 'Liquid') { this.tempSealibilityList.push(' L') }
      else if (x === 'Structural') { this.tempSealibilityList.push(' St') }
      else if (x === 'Gas') { this.tempSealibilityList.push(' G')}
    });
    return this.tempSealibilityList;
  }

  getPressFromList(data: any) {
    if (this.unitOfMeasure === "US") {
      this.unitOfPressure = 'psi';
      this.unitOfTension = 'klbf';
    }
    if (this.unitOfMeasure === "Metric") {
      this.unitOfPressure = 'kPa';
      this.unitOfTension = 'kN';
    }
    return this.sectionArrayList.flatMap(x => x.loadArray.filter(x => x.name === data))
      .flatMap(x => x.pressureAndTensionList);
  }
  getCombinedSealLocationString() {
    if (this.candidateConnection && typeof this.candidateConnection.sealLocPin != 'undefined' && this.candidateConnection.sealLocPin)
      this.sealLocationString += this.candidateConnection.sealLocPin + '\n';
    else if (this.candidateConnection && typeof this.candidateConnection.sealLocBox != 'undefined' && this.candidateConnection.sealLocBox)
      this.sealLocationString += this.candidateConnection.sealLocMid + '\n';
    else if (this.candidateConnection && typeof this.candidateConnection.sealLocMid != 'undefined' && this.candidateConnection.sealLocMid)
      this.sealLocationString += this.candidateConnection.sealLocBox + '\n';
  }
  getCombinedShoulderLocationString() {
    if (this.candidateConnection && this.candidateConnection.shoulderLocNone != "")
      this.shoulderLocationString += this.candidateConnection.shoulderLocNone + '\n';
    else if (this.candidateConnection && this.candidateConnection.shoulderLocPin != "")
      this.shoulderLocationString += this.candidateConnection.shoulderLocPin + '\n';
    else if (this.candidateConnection && this.candidateConnection.shoulderLocMid != "")
      this.shoulderLocationString += this.candidateConnection.shoulderLocMid + '\n';
    else if (this.candidateConnection && this.candidateConnection.shoulderLocBox != "")
      this.shoulderLocationString += this.candidateConnection.shoulderLocBox + '\n';
  }
}
