import { Component, OnInit, Input } from '@angular/core';
import { ITableHeader } from '../../../../common/models/components/iTableHeader';
import { ITableRow } from '../../../../common/models/components/iTableRow';
import { CandidateConnectionResult } from '../../../../common/models/responses/candidateConnectionResult';
import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/iCandidateConnectionResult.interface';

@Component({
  selector: 'app-tests-information-tab',
  templateUrl: './tests-information-tab.component.html',
  styleUrls: ['./tests-information-tab.component.css']
})
export class TestsInformationTabComponent implements OnInit {
  @Input() candidateConnectionResult: ICandidateConnectionResult = new CandidateConnectionResult();
  headerList: ITableHeader[] = [{ name: 'PROCEDURE', errorMessage: '', isNumeric: false, isSortable: true },
  { name: 'SPECIMEN', errorMessage: '', isNumeric: false, isSortable: true },
  { name: 'MATERIAL GRADE', errorMessage: '', isNumeric: false, isSortable: true },
  { name: 'TEST', errorMessage: '', isNumeric: false, isSortable: true },
  { name: 'DATE', errorMessage: '', isNumeric: false, isSortable: true },
  { name: 'FACILITY', errorMessage: '', isNumeric: false, isSortable: true },
  { name: 'COMMENTS', errorMessage: '', isNumeric: false, isSortable: true }
  ];

  tableRowList: ITableRow[] = [];
  constructor() { }

  ngOnInit(): void {
    this.tableRowList = this.getTableRowList();
  }

  onSortByChange(header: ITableHeader) {
    let isAscending = header.isAscending;
    let value = header.name;
    switch (value) {
      case 'PROCEDURE':
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.testProcedure > b.testProcedure ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
        break;
      case 'SPECIMEN':
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.specimenType > b.specimenType ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
        break;
      case 'MATERIAL GRADE':
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.materialGrade > b.materialGrade ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
        break;
      case 'TEST':
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.testType > b.testType ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
        break;
      case 'DATE':
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.testDate > b.testDate ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
        break;
      case 'FACILITY':
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.testFacility > b.testFacility ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
        break;
      case 'COMMENTS':
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.comments > b.comments ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
        break;
      default:
        this.candidateConnectionResult.testSpecimens = this.candidateConnectionResult.testSpecimens.sort((a, b) => (a.testProcedure > b.testProcedure ? (isAscending ? 1 : -1) : (isAscending ? -1 : 1)));
    }
    this.tableRowList = this.getTableRowList();
  }


  getTableRowList() {
    let tableRowList: ITableRow[] = [];
    this.candidateConnectionResult.testSpecimens.forEach((test) => {
      let tableRow: ITableRow = {
        colspan: 1, editable: false, tdColumn: [{ editable: false, headerName: 'PROCEDURE', value: test.testProcedure }, { editable: false, headerName: 'SPECIMEN', value: test.specimenType },
        { editable: false, headerName: 'MATERIAL GRADE', value: test.materialGrade }, { editable: false, headerName: 'TEST', value: test.testType }, { editable: false, headerName: 'DATE', value: test.testDate },
        { editable: false, headerName: 'FACILITY', value: test.testFacility }, { editable: false, headerName: 'COMMENTS', value: test.comments }]
      };
      tableRowList.push(tableRow);
    });
    return tableRowList;
  }
}
