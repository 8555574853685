<div class="em-l-container">
  <div class="em-c-page-header " *ngIf="unauthorized">
    <h1 class="em-c-page-header__title">Unauthorized</h1>
    <p class="em-c-page-header__desc">
      '{{ userEmail }}' is not an authorized TCDB Web App user.
    </p>
    <p>
      WellView Cloud users are primarily authorized to use this tool. If you
      want access, go to
      <a href="http://goto/itservices" target="_blank">IT Services</a> and
      request access to the <strong>'WELLVIEW_CLOUD_EDITORS.UG'</strong> LAN
      group using the <strong>LAN Single Group Update</strong> form.
    </p>
  </div>
  <div class="em-c-page-header " *ngIf="!unauthorized">
    <h1 class="em-c-page-header__title">Authorized</h1>
    <p class="em-c-page-header__desc">
      You are authorized! <a routerLink="/reports">Click here</a> to continue.
    </p>
  </div>
</div>
