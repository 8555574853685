<div class="em-l em-l--two-column-fixed">
  <div class="em-l__secondary">
    <header class="em-c-header em-js-header em-c-header--vertical"
            role="banner">
      <div class="em-c-header__inner">
        <div class="em-c-header__nav-container em-js-nav-panel">
          <div class="em-c-solid-nav">
            <ul class="em-c-solid-nav__list">
              <li class="em-c-solid-nav__item">
                <input type="file" class="file-input" accept=".xlsx,.csv" (change)="onFileSelected($event)" #fileUpload data-test-id="input-file">
                <a href="javascript:" (click)="importConfirmationModal()" class="em-c-solid-nav__link" data-testid="import-file">
                  <svg class="em-c-icon em-c-icon--small em-c-solid-nav__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#download"></use>
                  </svg>
                  Import
                </a>
              </li>
              <li class="em-c-solid-nav__item">
                <a href="javascript:" class="em-c-solid-nav__link" (click)="exportLoadToExcel()">
                  <svg class="em-c-icon em-c-icon--small em-c-solid-nav__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#upload"></use>
                  </svg>
                  Export
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="em-c-field" *ngIf="!wellModel.wellName && !wellModel.caseDescription">
          <a href="javascript:void(0);" data-testid="addwelldetails" class="em-c-tree__link em-js-table-of-contents-itemem-js-table-of-contents-item em-js-table-of-contents-item"
             (click)="openSectionModalForWell()">
            <span class="em-c-btn__text" style="color: #0c69b0">+ Add Well details</span>
          </a>
        </div>
        <div class="em-c-field" *ngIf="wellModel.wellName">
          <span class="em-c-tree__link em-js-table-of-contents-itemem-js-table-of-contents-item em-js-table-of-contents-item">{{wellModel.wellName}}</span>
          <app-expansion-panel [expansionFilter]='expansionFilter' (iconActionEvent)="onWellActionClick($event,load.wellName)" class="controlIcon"></app-expansion-panel>
        </div>
        <div class="em-c-field" *ngIf="wellModel.caseDescription && !wellModel.wellName">
          <a href="javascript:void(0);" data-testid="editwelldetails" class="em-c-tree__link em-js-table-of-contents-itemem-js-table-of-contents-item em-js-table-of-contents-item"
             (click)="openSectionModalForWellEdit()">
            <span class="em-c-btn__text" style="color: #0c69b0">+ Edit Well details</span>
          </a>
        </div>
        <app-section (addLoadEvent)="addLoad($event)" (editLoadEvent)="editLoad($event)" [sectionArray]="sectionArray" (sectionAdded)="sectionAdded()" (deleteLoadEvent)="deleteLoadFromEvent($event)" (deleteSectionEvent)="sectionDeleted()"></app-section>
      </div>
    </header>
  </div>

  <!--AddLoad-->
  <div class="em-l__main em-u-padding-top-double main-content">
    <main role="main">
      <div>
        <app-alert-success [message]="successMessage" (closeMessageEvent)="closeSuccessMessage()"></app-alert-success>
        <app-alert-error></app-alert-error>
      </div>
      <div id="WellNameCaseDesc" class="em-u-padding-bottom-double" style="font-size:21px" *ngIf="wellModel.wellName || wellModel.caseDescription">
        <p class="rounded-line">
          <span *ngIf="wellModel.wellName" class="tab-spacing"><strong>Well Name:&nbsp;</strong>{{wellModel.wellName}}</span>
          <span *ngIf="wellModel.caseDescription"><strong>Case Description:&nbsp;</strong><span class="content-block">{{wellModel.caseDescription}}</span></span>
        </p>
      </div>
      <div id="LoadInformation" *ngIf="showLoad">
        <h3>Load Information</h3>

        <div class="em-c-field" [ngClass]="loadNameError ? 'em-has-error' : ''">
          <label for="" class="em-c-field__label">Load Name</label>
          <div class="em-c-field__body">
            <p><input type="text" id="loadName" class="em-c-input" #loadName [(ngModel)]="loadNameForm"></p>
          </div>
          <div class="em-c-field__note" *ngIf="!loadNameError">This field is required and must be unique for each section</div>
          <div class="em-c-field__note" *ngIf="loadNameError">{{loadNameErrorMessage}}</div>
        </div>
        <div class="em-c-field em-c-field--checkbox" [ngClass]="sealabilityError ? 'em-has-error' : ''">
          <h4 for="" class="em-c-field__label">Sealability Envelopes</h4>
          <div class="em-c-field__body">
            <ul class="em-c-option-list">
              <li class="em-c-option-list__item" *ngFor="let item of load.sealabilityEnvelopes">
                <label class="em-c-input-group em-c-checkbox--container">
                  <input data-testid="seability-checkboxes" type="checkbox"
                         name="{{item.name}}"
                         value="{{item.name}}"
                         class="em-c-input-group__control"
                         (change)="onCheckboxChange($event,item.name)"
                         [checked]="item.checked" />
                  <span class="em-c-checkbox--checkmark"></span>
                  <span class="em-c-input-group__text" style="color:black;">{{item.name}}</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="em-c-field__note" *ngIf="sealabilityError">At least one must be selected</div>
          <div class="em-c-field__note" *ngIf="!sealabilityError">This field is required and must be unique for each section</div>
        </div>
        <div class="em-c-field" [ngClass]="loadCommentsError ? 'em-has-error' : ''">
          <label for="" class="em-c-field__label">Load Comments</label>
          <div class="em-c-field__body">
            <p><textarea id="loadComments" class="em-c-textarea" cols="40" rows="3" #loadComments [(ngModel)]="loadCommentsForm"></textarea></p>
          </div>
          <div class="em-c-field__note" *ngIf="loadCommentsError">{{loadCommentsErrorMessage}}</div>
        </div>

        <div class="em-c-field">
          <label for="" class="em-c-field__label">Pressure and Tension Loads</label>
          <div class="em-c-field__body">
            <div class="scrollbar">
              <div class="fpo-block">
                <app-display-table [tableHeaderList]="pressureAndTensionHeader" [tableRowList]="pressureAndTensionLoadList" [addRow]="useAddRow" (addRowEvent)="addPressureAndTension($event)" (deleteRowEvent)="deletePressureAndTension($event)"></app-display-table>
              </div>
            </div>
          </div>
        </div>

        <div class="em-c-btn-group" style="width:30%;">
          <button class="em-c-btn em-c-btn--primary" (click)="saveLoad(loadName.value,loadComments.value)" data-testid="saveCreateLoad">
            <span class="em-c-btn__text" *ngIf="!isToEdit">Create</span>
            <span class="em-c-btn__text" *ngIf="isToEdit">Save</span>
          </button>

          <button class="em-c-btn em-c-btn--secondary" (click)="resetAction()" data-testid="resetLoad">
            <span class="em-c-btn__text">Reset</span>
          </button>
          <button class="em-c-btn em-c-btn--tertiary">
            <span class="em-c-btn__text" *ngIf="!isToEdit" (click)="cancel()">Cancel</span>
            <span class="em-c-btn__text" *ngIf="isToEdit" (click)="openDeleteLoadConfirmationModal()" data-testid="deleteLoadPage">Delete</span>
          </button>
        </div>
      </div>
      <div id="LoadInformation" *ngIf="!showLoad">
        <app-landing-page [pageTitle]="loadEmptyTitle" [pageMessage]="loadEmptyMessage" [paddingValue]="paddingVal" [addSectionAndUploadFileButton]="isToShowButtonsInLandingPage" (addSectionEvent)="addSectionFromLandingPage()" (uploadFromFileEvent)="fileUpload.click()"></app-landing-page>
      </div>

    </main>
  </div>
  <!-- end Add Load -->
  <!--Section Modal-->
  <div>
    <div class="em-js" id="div_modal_start" *ngIf="sectionModalOpenForWell">
      <div class="em-c-modal em-js-modal" id="modal" [ngClass]="!sectionModalOpenForWell ? 'em-is-closed' : ''">
        <div class="em-c-modal__window em-js-modal-window">
          <div class="em-c-modal__header header-color">
            <h3 class="em-c-modal__title">Well Details</h3>
            <!--<button class="em-c-btn em-c-btn--bare em-c-modal__close-btn em-js-modal-cancel-trigger">
              <div class="em-c-btn__inner">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="../../images/em-icons.svg">
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../images/em-icons.svg#x-filled"></use>
                </svg>
              </div>
            </button>-->
          </div>
          <div class="em-c-modal__body">
            <div class="em-c-field ">
              <label for="" class="em-c-field__label">Well Name</label>
              <div class="em-c-field__body">
                <input type="text" id="wellname" class="em-c-input em-js-input" placeholder="Well Name" value="{{wellModel.wellName}}" #wellName />
              </div>
              <div class="em-c-field__note errorText" *ngIf="wellNameError">{{wellNameErrorMessage}}</div>
              <div class="em-c-field ">
                <label for="" class="em-c-field__label">Case Description</label>
                <div class="em-c-field__body">
                  <textarea id="casedescription" class="em-c-input em-js-input" cols="20" rows="5" placeholder="Case Description" value="{{wellModel.caseDescription}}" #caseDescription></textarea>
                </div>
                <div class="em-c-field__note errorText" *ngIf="caseDescriptionError">{{caseDescriptionErrorMessage}}</div>

              </div>
              <div class="em-c-btn-group em-c-modal--alert em-js-modal-only">
                <button class="em-c-btn em-c-btn--primary em-js-modal-confirm-trigger" (click)="createOrEditWellDesc(wellName.value,caseDescription.value, isToEdit)">
                  <span class="em-c-btn__text" *ngIf="isToEdit">Update</span>
                  <span class="em-c-btn__text" *ngIf="!isToEdit">Create</span>
                </button>
                <button class="em-c-btn em-c-btn--secondary em-js-modal-cancel-trigger" (click)="closeSectionModalForWell()">
                  <span class="em-c-btn__text">Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Section Modal-->

  <app-confirmation-modal [confirmationText]="loadConfirmationText" [showConfirmationModal]="loadConfirmationModalOpen" (emitResult)="deleteLoad($event)" [confirmationTitle]="loadTitleConfirmationText"></app-confirmation-modal>

  <app-confirmation-modal [confirmButtonText]="'Yes'" [confirmationText]="importLoadsConfirmationModalText" [showConfirmationModal]="showImportConfirmationModal" (emitResult)="importLoad($event)" [confirmationTitle]="'Confirm Import'"></app-confirmation-modal>

  <app-confirmation-modal [confirmationText]="wellConfirmationText" [showConfirmationModal]="wellConfirmationModalOpen" (emitResult)="deleteWellDetails($event)" [confirmationTitle]="wellTitleConfirmationText"></app-confirmation-modal>


  <app-export-to-excel [isBlankTemplate]="isBlankExcelTemplate" [sheetList]="excelExport" [fileName]="fileName"></app-export-to-excel>
</div>
