export enum LogoPath {
  Antares = "antares.png",
  AtlasBradford = "atlas-bradford.png",
  Benoit = "benoit.png",
  GE = "ge.png",
  Hunting = "hunting.png",
  JFE = "jfe.png",
  Mannesmann = "mannesmann.png",
  NipponSteel = "nippon-steel.png",
  NKK = "nkk.png",
  NOV = "nov.png",
  OSI = "osi.png",
  Precision = "precision.png",
  Sumitomo = "sumitomo.png",
  TenarisHydril = "tenaris-hydril.png",
  TMK = "tmk.png",
  TPCO = "tpco.png",
  Tubacex = "tubacex.png",
  VAM = "vam.png",
  Default = "default.png"
}
