import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { CoreMainComponent } from './pages/core-main/core-main.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { LoadingComponent } from '../main/components/loading/loading.component';
import { ExpandableMenuComponent } from './components/expandable-menu/expandable-menu.component';
import { SharedModule } from '../shared/shared.module';
import { ExportToExcelComponent } from '../tcdb/components/export-to-excel/export-to-excel.component';

@NgModule({
  declarations: [CoreMainComponent, LoadingComponent, MainHeaderComponent, MainFooterComponent, UnauthorizedComponent, ExpandableMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ]
})
export class MainModule { }
