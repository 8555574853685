import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})

export class AppConfigService {
  private appConfig: { msal: any, guard: MsalGuardConfiguration, interceptor: MsalInterceptorConfiguration, appSettings: Object };
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
    this.appConfig = {
      msal: {},
      guard: { interactionType: InteractionType.Redirect },
      interceptor: { interactionType: InteractionType.Redirect, protectedResourceMap: new Map() },
      appSettings: {}
    };
  }

  init(endpoint: string, configPath: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      this.http.get(configPath)
        .subscribe((localConfig: any) => {

          const keyText = localConfig.appSettings.apiKeyText;
          const encodedText = btoa(keyText);
          const headers = new HttpHeaders({ EncodedKey: `${encodedText}` });

          this.http.get(endpoint, { headers }).subscribe((apiRes: any) => {

            // Reattach if custom values are provided for other configuration
            this.appConfig.msal = Object.assign(localConfig.msal, apiRes.msal);
            this.appConfig.appSettings = Object.assign(localConfig.appSettings, apiRes.appSettings);
            this.appConfig.guard = localConfig.guard;

            // Load default protected resources from app-config.json file
            if (Array.isArray(localConfig.interceptor.protectedResourceMap) && localConfig.interceptor.protectedResourceMap.length > 0) {
              for (let index = 0; index < localConfig.interceptor.protectedResourceMap.length; index++) {
                this.appConfig.interceptor.protectedResourceMap.set(localConfig.interceptor.protectedResourceMap[index][0], localConfig.interceptor.protectedResourceMap[index][1])
              }
              // Load custom protected resources
              // This is MUST for interceptor to provide token for custom API calls.
              this.appConfig.interceptor.protectedResourceMap.set(`${this.appConfig.msal.auth.redirectUri}api/*`, [apiRes.appSettings.scope]);
            }
            resolve(true);
          })
        },
          (error) => {
            reject(error);
          });
    });
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.appConfig;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    let result = key.reduce((account: any, current: string) => account && account[current], this.appConfig);
    return result;
  }
}
