<ol class="em-c-pagination" role="navigation" aria-labelledby="pagination-label" [ngClass]="innerClass" [style]="innerStyle">
  <li class="em-c-pagination__item" *ngIf="showArrow()">
    <a class="em-c-pagination__link link" [class.em-is-disabled]="currentPage === startPage" (click)="movePage(-1);">
      <svg class="em-c-icon em-c-icon--small em-c-pagination__icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#caret-left"></use>
      </svg>
    </a>
  </li>
  <li class="em-c-pagination__item" *ngFor="let page of currentRange" (click)="onClick(page)">
    <a class="em-c-pagination__link link" [class.em-is-current]="page === currentPage">
      {{ page }}
    </a>
  </li>
  <li class="em-c-pagination__item" *ngIf="showArrow()">
    <a class="em-c-pagination__link link" [class.em-is-disabled]="currentPage === endPage" (click)="movePage(1);">
      <svg class="em-c-icon em-c-icon--small em-c-pagination__icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#caret-right"></use>
      </svg>
    </a>
  </li>
</ol>
