<section class="em-c-section em-c-section--expandable section-child" [ngClass]="isClosedChild ? 'em-is-closed' : ''">
  <header class="em-c-section__header em-js-section-trigger">
    <label>
      <input data-testid="expansion-panelcheckbox" type="checkbox"
             [id]="getElementId(title)"
             name="{{parentTitle}}.{{title}}"
             value="{{title}}"
             class="em-c-input-group__control"
             (change)="onCheckboxChange($event,title,false)"
             [checked]="expansionPanelItem.checked" />
      {{title}}
    </label>
    <svg class="em-c-icon em-c-icon--medium em-c-section__icon" (click)="onExpansionChild()">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#caret-down"></use>
    </svg>
  </header>
  <!-- end em-c-section-header -->

  <div class="em-c-section__body em-js-section-target child-component">
    <div *ngFor="let item of expansionPanelItem.items" class="em-c-dropdown-check__item">
      <label>
        <input data-testid="expansion-panelcheckbox" type="checkbox"
               [id]="getChildElementId(item)"
               name="{{parentTitle}}.{{title}}.{{item.value}}"
               [value]="getChildElementId(item)"
               class="em-c-input-group__control"
               (change)="onCheckboxChange($event,item.value,true)"
               [checked]="item.checked"/>
        {{item.name}}
      </label>
    </div>
  </div>
  <!-- end em-c-section__body -->
</section>
<!-- end em-c-section -->
