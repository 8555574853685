<section class="em-c-section em-c-section--expandable" [ngClass]="isClosed || forceCollapse ? 'em-is-closed' : ''">
  <header class="em-c-section__header em-js-section-trigger" *ngIf="!expansionFilter.icon">
    <svg class="em-c-icon em-c-icon--medium em-c-section__icon" (click)="onExpansion()">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#caret-down"></use>
    </svg>
    <h2 class="em-c-section__title" (click)="onExpansion()">
      {{expansionFilter.title}}
    </h2>
  </header>

  <div *ngIf="expansionFilter.icon">
    <a href="javascript:void(0);" class="em-c-tree__link no-padding icon-position">
      <svg class="em-c-icon em-c-icon--small" (click)="onExpansion()">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" [attr.xlink:href]="getIconValue()"></use>
      </svg>
    </a>
  </div>

  <div class="em-c-section__body em-js-section-target" [ngClass]="expansionFilter.icon ? 'dropdown' : ''">
    <div [ngClass]="expansionFilter.icon ? 'dropdown-content' : ''">
      <div *ngFor="let item of expansionFilter.items" class="em-c-dropdown-check__item">
        <label *ngIf="!expansionFilter.enableSecondLevel && !expansionFilter.icon">
          <input *ngIf="expansionFilter.usecheckbox" data-testid="expansion-panelcheckbox" type="checkbox"
                 [id]="getElementId(item.value)"
                 name="{{expansionFilter.title}}.{{item.value}}"
                 value="{{item.value}}"
                 class="em-c-input-group__control"
                 (change)="onCheckboxChange($event,item.value)"
                 [checked]="item.checked" />
          {{item.name}}
        </label>


        <a *ngIf="!expansionFilter.enableSecondLevel && expansionFilter.icon" href="javascript:void(0);" class="em-c-tree__link no-padding" (click)="onItemClick(item.value)">
          {{item.name}}
        </a>

        <div *ngIf="expansionFilter.enableSecondLevel">
          <app-expansion-panel-child [expansionPanelItem]='item' (checkBoxEvent)="onChildCheckboxChange($event)" [title]="item.name" [parentTitle]="expansionFilter.title"></app-expansion-panel-child>
        </div>
      </div>
    </div>
  </div>
  <!-- end em-c-section__body -->


</section>
<!-- end em-c-section -->
