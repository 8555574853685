<div class="landing-heading">
  <span style="font-size: xxx-large;"><span [ngClass]="paddingValue"></span>{{pageTitle}}</span><br />
  <span style="font-size: x-large;">{{pageMessage}}</span>

  <div *ngIf="addSectionAndUploadFileButton" class="div-padding-top">
    <button class="em-c-btn em-c-btn--primary" (click)="onAddSectionClick()">
      <div class="em-c-btn__inner">
        <svg class="em-c-icon em-c-icon--medium em-c-btn__icon " data-em-icon-path="../../../../assets/vendor/unity/images/em-icons.svg">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../assets/vendor/unity/images/em-icons.svg#plus-filled"></use>
        </svg>
        <span class="em-c-btn__text">Add Section</span>
      </div>
    </button>

    <button class="em-c-btn em-c-btn--secondary button-margin" (click)="onUploadFromFileClick()">
      <div class="em-c-btn__inner">
        <svg class="em-c-icon em-c-icon--medium em-c-btn__icon " data-em-icon-path="../../../../assets/vendor/unity/images/em-icons.svg">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../assets/vendor/unity/images/em-icons.svg#download"></use>
        </svg>
        <span class="em-c-btn__text">Upload from File</span>
      </div>
    </button>
  </div>

</div>
