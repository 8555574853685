import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UnitOfMeasure } from '../../common/Enums/unitOfMeasure';
import { UnitOfMeasureModel } from '../../common/models/unitOfMeasureModel';

@Injectable({ providedIn: 'root' })
export class UnitOfMeasureService {
  private unitOfMeasure = 'unitOfMeasure';
  private fromUnitOfMeasure = 'fromUnitOfMeasures';
  public savedUnitOfMeasure = new BehaviorSubject<UnitOfMeasureModel>({ unitOfMeasure: UnitOfMeasure.US, reloadFilters: false });


  constructor() {
    this.savedUnitOfMeasure.next(UnitOfMeasure[this.getUnitOfMeasure()]);
  }
  //save the selected UOM in local
  public setUnitOfMeasure(value: string, reloadFilters: boolean = false) {
    let unitOfMeasureType: UnitOfMeasure = UnitOfMeasure[value];
    this.setFromUnitOfMeasure(this.getUnitOfMeasure());
    localStorage.setItem(this.unitOfMeasure, unitOfMeasureType);
    this.savedUnitOfMeasure.next({ unitOfMeasure: unitOfMeasureType, reloadFilters: reloadFilters });
  }

  public getUnitOfMeasure() {
    var unitOfMeasure = localStorage.getItem(this.unitOfMeasure);

    if (!unitOfMeasure) {
      unitOfMeasure = UnitOfMeasure.US;
      localStorage.setItem(this.unitOfMeasure, unitOfMeasure);
    }

    return unitOfMeasure;
  }

  public setFromUnitOfMeasure(value) {
    let uom: UnitOfMeasure = !value ? UnitOfMeasure.US : UnitOfMeasure[value];
    localStorage.setItem(this.fromUnitOfMeasure, uom);
  }

  public getFromUnitOfMeasure() {
    return localStorage.getItem(this.fromUnitOfMeasure);
  }

  public removeUnitOfMeasure() {
    localStorage.removeItem(this.unitOfMeasure);
  }

}
