import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { IExpansionPanelItem, IExpansionPanelModel } from '../../../../common/models/components/expansionpanelmodel.interface';
import { CollapseService } from '../../../../service/tcdb/collapse.service';


@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.css']
})
export class ExpansionPanelComponent implements OnInit {
  @Input() children = [];
  @Input() title = '';
  @Input() childrenOptions = [];
  @Input() expansionFilter: IExpansionPanelModel = { title: '', items: [], usecheckbox: false, sort: 0, enableSecondLevel: false, icon: '' };
  @Output() checkBoxEvent = new EventEmitter();
  @Output() iconActionEvent = new EventEmitter<string>();

  public checkboxOptions = [];
  isClosed = true;
  forceCollapse = false;

  constructor(private _collapseService: CollapseService) {
  }

  ngOnInit() {
    this.checkboxOptions = Array.from(this.expansionFilter.items);
    if (this.expansionFilter.icon) {
      this._collapseService.collapse.subscribe((_) => {
        this.forceCollapse = true;
        if (!this.isClosed) {
          this.isClosed = true;
        }
      });
    }
  }

  onCheckboxChange(event: any, value: string) {
    var checkboxValue = this.expansionFilter.items.find(obj => { return obj.value == value });
    checkboxValue.checked = event.target.checked;
    this.checkBoxEvent.emit(this.expansionFilter);
  }

  onChildCheckboxChange(expansionPanelItem: IExpansionPanelItem) {
    this.checkBoxEvent.emit(this.expansionFilter);
  }

  onExpansion() {
    this.forceCollapse = false;
    this.isClosed = !this.isClosed;

    if (this.expansionFilter.icon && !this.isClosed) {
      this._collapseService.publishCollapse();
      this.forceCollapse = false;
      this.isClosed = false;
    }
  }

  getElementId(item) {
    var elementId = this.expansionFilter.title.trim().replace(/\s/g, "") + ".";

    if (Number(item).toString() === 'NaN') {
      elementId += item.trim().replace(/\s/g, "")
    }
    else {
      elementId += item.toString();
    }
    elementId = elementId.toLowerCase();
    return elementId;
  }

  getIconValue() {
    return "../../../../../assets/vendor/unity/images/em-icons.svg#" + this.expansionFilter.icon;
  }

  onItemClick(item) {
    this.iconActionEvent.emit(item);
    this.isClosed = true;
  }

}
