import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private router: Router, private msalService: MsalService) { }

  unauthorized = true;
  userEmail = 'Unknown';

  ngOnInit() {
    let account = this.msalService.instance.getAllAccounts()[0];
    if (account === null) {
      this.msalService.loginRedirect();
    }
    else {
      this.unauthorized = false;
      this.router.navigateByUrl('');
    }
  }
}
