<ul class="em-c-tags em-c-tags--closing">
  <li class="em-c-tags__item em-js-tags-item" *ngFor="let item of filterChipItems">
    <div *ngIf="item.isTitle">
      <span class="em-c-tags__text">
        {{item.name}}
      </span>
    </div>

    <div class="em-c-tags__link em-js-tags-trigger" *ngIf="!item.isTitle">
      <span class="em-c-tags__text">
        {{getNameToShow(item)}}
      </span>
      <a href="javascript:void(0);" (click)="closeFilter(item)" [attr.data-testid]='"filterchip-" + item.name'>
        <svg class="em-c-icon em-c-icon--small em-c-tags__icon-inside">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#x-filled"></use>
        </svg>
      </a>
    </div>
  </li>
</ul>

