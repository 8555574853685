import { Component, Input } from '@angular/core';
import * as XLSX from 'xlsx';
import { IExportToExcel } from '../../../../common/models/components/iExportToExcel';
import { alphabetArray } from '../../../../common/utilities/utilities';


@Component({
  selector: 'app-export-to-excel',
  templateUrl: './export-to-excel.component.html',
  styleUrls: ['./export-to-excel.component.css']
})
export class ExportToExcelComponent {
  @Input() sheetList: IExportToExcel[] = [];
  @Input() fileName: string = '';
  @Input() isBlankTemplate: boolean;

  constructor() { }

  getSheetId(sheet: IExportToExcel) {
    if (this.isBlankTemplate)
      return "table-excel-" + sheet.sheetName + "-blank-template";
    return "table-excel-" + sheet.sheetName;
  }

  exportToExcel() {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    this.sheetList.forEach((sheet) => {
      let tableSheet = document.getElementById(this.getSheetId(sheet));
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tableSheet);

      let rowIndex: number = 1;
      let columnIndex: number = 0;
      sheet.rows.forEach(row => {
        columnIndex = 0;
        row.columns.forEach(column => {
          if (column.formula) {
            let cell = alphabetArray()[columnIndex] + rowIndex;
            ws[cell] = { t: 'n', f: column.formula }
          }
          columnIndex++;
        })
        rowIndex++;
      });
      /* generate workbook and add the worksheet */
      XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName);
    });

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


}
