import { InjectionToken, NgModule, APP_INITIALIZER } from '@angular/core';
import {
  IPublicClientApplication, PublicClientApplication,
  LogLevel
} from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService,
  MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService } from './service/app-config/app-config.service';

const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');
const AUTH_CONFIG_PATH_TOKEN = new InjectionToken<string>('AUTH_CONFIG_PATH');

export function initializerFactory(env: AppConfigService, configUrl: string, configPath: string): any {
  const promise = env.init(configUrl, configPath).then((value) => {
    console.log('finished getting configurations dynamically.');
  });
  return () => promise;
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(config: AppConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: config.getSettings('msal').auth,
    cache: config.getSettings('msal').cache,
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(config: AppConfigService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>(config.getSettings('interceptor').protectedResourceMap)

  return {
    interactionType: config.getSettings('interceptor').interactionType,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(config: AppConfigService): MsalGuardConfiguration {
  return {
    interactionType: config.getSettings('guard').interactionType,
    authRequest: config.getSettings('guard').authRequest,
    loginFailedRoute: config.getSettings('guard').loginFailedRoute
  };
}

@NgModule({
  providers: [],
  imports: [MsalModule]
})
export class AppAuthModule {

  static forRoot(configUrl: string, configPath: string) {
    return {
      ngModule: AppAuthModule,
      providers: [
        AppConfigService,
        { provide: AUTH_CONFIG_URL_TOKEN, useValue: configUrl },
        { provide: AUTH_CONFIG_PATH_TOKEN, useValue: configPath },
        {
          provide: APP_INITIALIZER, useFactory: initializerFactory,
          deps: [AppConfigService, AUTH_CONFIG_URL_TOKEN, AUTH_CONFIG_PATH_TOKEN], multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AppConfigService]
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [AppConfigService]
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [AppConfigService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        }
      ]
    };
  }
}
