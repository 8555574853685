import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { IExpansionPanelChildItem, IExpansionPanelItem, IExpansionPanelModel } from '../../../../../common/models/components/expansionpanelmodel.interface';


@Component({
  selector: 'app-expansion-panel-child',
  templateUrl: './expansion-panel-child.component.html',
  styleUrls: ['./expansion-panel-child.component.css']
})
export class ExpansionPanelChildComponent {
  @Input() title = '';
  @Input() parentTitle = '';
  @Input() expansionPanelItem: IExpansionPanelItem = { name: '', value: '', checked: false, items: [] };
  @Output() checkBoxEvent = new EventEmitter <IExpansionPanelItem>();


  isClosedChild = true;
  constructor() {
  }

  onCheckboxChange(event: any, value: string, isChild: boolean) {
   
    if (isChild) {
      var checkboxValue = this.expansionPanelItem.items.find(obj => { return obj.value == value });
      checkboxValue.checked = event.target.checked;
      var checkedChildren = this.expansionPanelItem.items.filter(s => { return s.checked == true });
      if (!this.expansionPanelItem.checked && checkedChildren.length > 0) {
        this.expansionPanelItem.checked = true;
      }
      else if (this.expansionPanelItem.checked && checkedChildren.length == 0) {
        this.expansionPanelItem.checked = false;
      }
    }
    else {
      this.expansionPanelItem.checked = event.target.checked;
      this.expansionPanelItem.items.forEach(p => {
        var childCheckboxValue = this.expansionPanelItem.items.find(obj => { return obj.value == p.value });
        childCheckboxValue.checked = event.target.checked;
      })
    }

    this.checkBoxEvent.emit(this.expansionPanelItem);
  }

  onExpansionChild() {
    this.isClosedChild = !this.isClosedChild;
  }

  getElementId(item: string) {
    return (this.parentTitle.trim().replace(/\s/g, "") + '.' + this.title.trim().replace(/\s/g, "") + '.' + item.trim().replace(/\s/g, "")).toLowerCase();

  }

  getChildElementId(item: IExpansionPanelChildItem) {
    if (item.isToUseJustValueAsId) {
      return item.value;
    }
    return this.getElementId(item.value);

  }

}
