export class load {
  name: string;
  comment: string;
  sealabilityEnvelopes: sealabilityEnvelope[];
  pressureAndTensionList: pressureAndTension[];
  
  constructor() {
    this.name = '';
    this.comment = '';
    this.sealabilityEnvelopes = [];
    this.pressureAndTensionList = [];
  }
}

export class sealabilityEnvelope {
  name: string;
  checked: boolean;
}

export class pressureAndTension {
  pressure?: number;
  tension?: number;
  imported: boolean;
  originalPressure?: number;
  originalTension?: number;
  uom?: string;
}

export class editLoad {
  sectionIndex: number;
  load: load;
}
