import { Component} from '@angular/core';
import { TabStorageService } from '../service/tcdb/tabstorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  constructor(private tabStorageService: TabStorageService) {
  }


}
