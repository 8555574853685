import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  styles: [':host { display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingPageComponent {
  @Input() pageTitle: string;
  @Input() pageMessage: string;
  @Input() paddingValue;
  @Input() addSectionAndUploadFileButton: boolean = false;
  @Output() addSectionEvent = new EventEmitter();
  @Output() uploadFromFileEvent = new EventEmitter;

  onAddSectionClick() {
    this.addSectionEvent.emit();
  }

  onUploadFromFileClick() {
    this.uploadFromFileEvent.emit();
  }

}
