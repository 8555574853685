<div>
  <nav class="em-c-tree " role="navigation">
    <ul class="em-c-tree__list em-c-tree__list--flush" role="tablist" aria-multiselectable="true" id="SectionRoot">
      <li class="em-c-tree__item " *ngFor="let section of sectionArray; let i=index" style="margin-top:20px;">
        <div class="em-l-grid__item align-end">
          <div class="em-c-field" style="width:100%" id="divSectionName">

            <p class="em-c-tree__link em-c-tree__link--has-children em-js-tree-dropdown-trigger">
              <svg (click)="collapse(i)" class="em-c-icon em-c-icon--small em-c-tree__icon"
                   *ngIf="isCollapsed[i]">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="/assets/vendor/unity/images/em-icons.svg#icon-caret-right"></use>
              </svg>
              <svg (click)="collapse(i)" class="em-c-icon em-c-icon--small em-c-tree__icon"
                   *ngIf="!isCollapsed[i]">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="/assets/vendor/unity/images/em-icons.svg#icon-caret-down"></use>
              </svg>
              <span class="em-c-tree__text sectionName" (click)="collapse(i)">{{section.name}}</span>

              <app-expansion-panel [expansionFilter]='expansionFilter' (iconActionEvent)="onSectionActionClick($event,section.name)" class="controlIcon"></app-expansion-panel>
            </p>
          </div>
        </div>

        <!--Load-->
        <ul class="em-c-tree__list em-js-tree-dropdown "
            [ngClass]="!isCollapsed[i] ? 'em-is-active' : ''">
          <li class="em-c-tree__item " *ngFor="let loadobj of section.loadArray">
            <div class="em-l-grid__item align-end">
              <div class="em-c-field" style="width:100%" id="divLoadName">

                <p class="em-c-tree__link em-c-tree__link--has-children em-js-tree-dropdown-trigger">
                  <span class="em-c-tree__text sectionName" (click)="editLoad(loadobj,i)"  [id]="'loadid-' + section.name + loadobj.name">{{loadobj.name}}</span>
                  <app-expansion-panel [expansionFilter]='expansionFilter' (iconActionEvent)="onLoadActionClick($event,loadobj.name,i)" class="controlIcon"></app-expansion-panel>
                </p>
              </div>
            </div>
          </li>
          <li class="em-c-tree__item">
            <a href="javascript:void(0);" class="em-c-tree__link" (click)="addLoad(i)" data-testid="addload">
              <span class="em-c-btn__text" style="color: #0c69b0">+ Add Load</span>
            </a>
          </li>
        </ul>
        <!--End Load-->

      </li>
      <li class="em-c-tree__item">
        <a href="javascript:void(0);" data-testid="addsection" class="em-c-tree__link em-js-table-of-contents-itemem-js-table-of-contents-item em-js-table-of-contents-item"
           (click)="openSectionModal()">
          <span class="em-c-btn__text" style="color: #0c69b0">+ Add Section</span>
        </a>
      </li>
    </ul>
  </nav>
</div>

<!--Section Modal-->
<div>
  <div class="em-js" id="div_modal_start" *ngIf="sectionModalOpen">
    <div class="em-c-modal em-js-modal" id="modal" [ngClass]="!sectionModalOpen ? 'em-is-closed' : ''">
      <div class="em-c-modal__window em-js-modal-window">
        <div class="em-c-modal__header header-color">
          <h3 class="em-c-modal__title">New Section</h3>
          <button class="em-c-btn em-c-btn--bare em-c-modal__close-btn em-js-modal-cancel-trigger">
            <div class="em-c-btn__inner">
              <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="../../images/em-icons.svg">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../images/em-icons.svg#x-filled"></use>
              </svg>
            </div>
          </button>
        </div>
        <div class="em-c-modal__body">
          <div class="em-c-field ">
            <label for="" class="em-c-field__label">Section Name</label>
            <div class="em-c-field__body">
              <input type="text" id="sectionname" class="em-c-input em-js-input" placeholder="Section Name" value="{{oldSectionName}}" #sectionname />
            </div>
            <div class="em-c-field__note" *ngIf="!sectionErrorMessage">This field must be unique.</div> <div class="em-c-field__note errorText" *ngIf="sectionErrorMessage">{{sectionErrorMessage}}</div>
          </div>
          <div class="em-c-btn-group em-c-modal--alert em-js-modal-only">
            <button class="em-c-btn em-c-btn--primary em-js-modal-confirm-trigger" (click)="createOrEditSection(sectionname.value, isToEdit, oldSectionName)" data-testid="createOrEditSection">
              <span class="em-c-btn__text" *ngIf="isToEdit">Update</span>
              <span class="em-c-btn__text" *ngIf="!isToEdit">Create</span>

            </button>
            <button class="em-c-btn em-c-btn--secondary em-js-modal-cancel-trigger" (click)="closeSectionModal()">
              <span class="em-c-btn__text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--End Section Modal-->

<!-- Confirmation Section Modal-->
<app-confirmation-modal [confirmationText]="sectionConfirmationText" [showConfirmationModal]="sectionConfirmationModalOpen" (emitResult)="deleteSectionAndLoad($event)" [confirmationTitle]="sectionTitleConfirmationText"></app-confirmation-modal>

<!--Load delete confirmation modal-->
<app-confirmation-modal [confirmationText]="loadConfirmationText" [showConfirmationModal]="loadConfirmationModalOpen" (emitResult)="deleteLoad($event)" [confirmationTitle]="loadTitleConfirmationText"></app-confirmation-modal>

