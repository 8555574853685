import { Component } from '@angular/core';

import { RouterModule } from '@angular/router';
import { MainFooterComponent } from '../../components/main-footer/main-footer.component';
import { MainHeaderComponent } from '../../components/main-header/main-header.component';

@Component({
  selector: 'app-core-main',
  templateUrl: './core-main.component.html',
  styleUrls: ['./core-main.component.css']
})
export class CoreMainComponent {
  constructor() { }

}
