//loader.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertErrorService {
  public errorMessage = new BehaviorSubject('');
  constructor() { }
}
