<div class="em-c-card em-c-card-maxwidth">
  <div class="em-c-card__body">
    <div class="em-c-media-block em-c-media-block--small">
      <div class="em-c-media-block__media image-div">
        <img src="assets/images/manufacturers/{{searchResultItem.manufacturerLogo}}" alt="alt text" class="em-c-media-block__img" />
      </div>
      <!-- end em-c-media-block__media -->
      <div class="em-c-media-block__body">
        <h2 class="em-c-media-block__headline search-title">
          <span>
            {{searchResultItem.od}} {{searchResultItem.odUOM}} OD, {{searchResultItem.weight}} {{searchResultItem.weightUOM}}, {{searchResultItem.gradesReportString}}, {{searchResultItem.manufacturer}}, {{searchResultItem.connectionName}} 
          </span>
        </h2>

        <div class="search-body">
          <p>
            <span>Connection Type:</span> {{searchResultItem.connectionTypeString}}
          </p>
          <p>
            <span>Thread Compound:</span> {{searchResultItem.threadCompound}}
          </p>
          <p>
            <span>Fully Evaluated:</span> {{searchResultItem.fullyEvaluated}}
          </p>
          <p>
            <span>High Torque:</span> {{searchResultItem.isHighTorque}}
          </p>
          <p *ngIf="searchResultItem.documentLink!==null">
            <span>Document #:</span> <a href={{searchResultItem.documentLink}} target="_blank"> {{searchResultItem.document}}</a>
          </p>
          <p *ngIf="searchResultItem.documentLink===null">
            <span>Document #:</span>  {{searchResultItem.document}}
          </p>
          <p>
            <span>Active:</span> {{searchResultItem.active}}
          </p>
        </div>
      </div>
      <!-- end em-c-media-block__body TEST-->
      <div class="em-c-status search-view-details">
        <!--<a class="em-c-btn" [routerLink]="['/viewdetails', searchResultItem.connectionId]">View Details</a>--> 
        <a href="javascript:void(0);" class="em-c-btn" (click)="onViewDetails(searchResultItem.connectionId)">View Details</a>
      </div>
    </div>
    <!-- end em-c-media-block -->
  </div>
  <!-- end em-c-card__body -->
</div>
<!-- end em-c-card -->
