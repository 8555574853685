export class Constants {
  //Expansion Panel Titles
  public static threadCompoundTitle: string = 'Thread Compound';
  public static materialTypeTitle: string = 'Material';
  public static connectionTypeTitle: string = 'Connection Type';
  public static connectionUseTitle: string = 'Connection Use';
  public static sizeTitle: string = 'Size (OD, Thickness, Weight)';
  public static YieldTitle: string = 'Yield';
  public static evaluationTitle: string = 'Evaluation';
  public static activeTitle: string = 'Active';
  public static docFilterTitle: string = 'Document Number';
  //Material Types
  public static CRA: string = 'Corrosion Resistant Alloy';
  public static carbonSteel: string = 'Carbon Steel';
  //Connection Types
  public static ThreadedAndCoupled: string = 'Threaded and Coupled';
  public static IntegralJoint: string = 'Integral Joint';
  public static SemiFlushJoint: string = 'Semi-Flush';
  public static FlushJoint: string = 'Flush Joint';
  //Connection Use
  public static Casing: string = 'Casing';
  public static HighTorque: string = 'High Torque';
  public static Casing_Tubing: string = 'Casing/Tubing';
  public static Tubing: string = 'Tubing';
  //Yield
  public static Yield: string = 'Yield';
  //
  public static ManufAndConnection: string = 'Manufacturer and Connection';
  public static LandingMessageTitle: string = 'No Filters Specified';
  public static LandingMessage: string = 'Apply one or more filters to view search results';

  //Confirmation Modal
  public static LoadDeleteConfirmation = '{{loadname}} load and all of its information will be deleted. Are you sure you want to proceed?';
  public static WellDeleteConfirmation = 'Well and all of its information will be deleted. Are you sure you want to proceed?';
  public static SectionDeleteConfirmation = '{{sectionname}} section and all of its load information will be deleted. Are you sure you want to proceed?';
  public static ImportLoadsConfirmation = 'Importing will overwrite the existing loads. Are you sure you want to proceed?';

  //Success messages
  public static LoadsExported = 'Loads exported successfully!';
  public static LoadsImported = 'Loads imported successfully!';
  public static SectionCreated = 'Section created successfully!';
  public static LoadCreated = 'Load created successfully!';
  public static LoadSaved = 'Load saved successfully!';

  //Error messages
  public static LoadsExtensionNotAllowed = 'This file is not allowed, just (.xlsx and .csv) are allowed to be imported';
  public static SectionNameCantBeEmpty = "The Section name can't be empty";
  public static WellNameCantBeEmpty = "The well name can't be empty";
  public static SectionAlreadyExists = 'This field must be unique.  Section {{sectionname}} already exists!';
  public static LoadNameEmpty = "The load name can't be empty";
  public static LoadNameNotUnique = 'This load name is not unique for this section';
  public static SealabilityEnvelopeError = 'At least one must be selected';
  public static LoadImportError = 'Could not import all the loads';
  public static SpecialCharacterError = 'Special characters are not allowed';
  public static TedIntegrationSpecialCharacter = 'Special characters [such as (, ), < etc.] from the [0] have been removed due to web security restrictions';

  //SealabilityTitles
  public static LiquidSealabilityTitle = 'Liquid Sealability Envelope';
  public static GasSealabilityTitle = 'Gas & Liquid Sealability Envelope';
  public static StructuralSealabilityTitle = 'Structural';

  //LoadEmptyStateMessage
  public static LoadEmptyMessageTitle: string = 'No Loads Selected';
  public static LoadEmptyMessage: string = 'Select an existing load to view details';
  public static LoadEmptyWithNoLoadsMessage: string = 'Add/Create a Load';
  public static LoadEmptyMessageEmptySectionTitle: string = 'No load information';
  public static LoadEmptyMessageEmptySectionMessage: string = 'Start by adding a section or uploading loads from a file';
  //Help Section Options
  public static userGuide: string = 'User Guide';
  public static loadExportTemplate: string = 'Load Import Template';
  public static contactUs: string = 'Contact Us';
  public static helpTitle: string = 'Help';
  //UoM Options
  public static unitUS: string = 'US';
  public static unitMetric: string = 'Metric';
  public static unitTitle: string = 'Units';
  //Urls
  public static urlWiki: string = 'https://wiki.na.xom.com/index.php/Casing_Design_Toolkit';
  public static urlSnow: string = 'https://emprod.service-now.com/itsp?id=sc_cat_item&sys_id=ae9e63181bf1b0901cf976ae034bcbab';
  //Unit
  public static pressureUS: string = 'psi';
  public static tensionUS: string = 'klbf';
  public static pressureMetric: string = 'kPa';
  public static tensionMetric: string = 'kN';
  //Sealability
  public static sealability: string[] = ['Gas', 'Liquid', 'Structural'];
}
