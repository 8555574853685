import { Component, Input } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UnitOfMeasureService } from '../../../../service/tcdb/unitofmeasure.service';
import { MainHeaderService } from '../../../../service/tcdb/mainheader.service';
import { Constants } from '../../../../common/constants/constant';
import { IExpandableMenuItem } from '../../../../common/models/components/iExpandableMenuItem';
import { SearchCriteriaService } from '../../../../service/tcdb/searchcriteria.service';
import { LoadCaseService } from '../../../../service/tcdb/loadcaseservice';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent {
  userName: string;
  unitOfMeasureList: IExpandableMenuItem[] = [{ name: Constants.unitUS }, { name: Constants.unitMetric }];
  helpList = [{ name: Constants.userGuide, url: Constants.urlWiki }, { name: Constants.loadExportTemplate }, { name: Constants.contactUs, url: Constants.urlSnow }];
  unitOfMeasureTitle = Constants.unitTitle;
  helpTitle = Constants.helpTitle;
  countOfLoads = 0;
  constructor(private _msalService: MsalService, private unitOfMeasureService: UnitOfMeasureService, private _mainHeaderService: MainHeaderService, private searchCriteria: SearchCriteriaService, private loadCaseService: LoadCaseService) {
    const account = this._msalService.instance.getAllAccounts()[0];
    this.userName = 'Welcome ' + account.name;
    this._mainHeaderService.GetLoadCount().subscribe(count => {
      this.countOfLoads = count;
    })
  }

  onUnitOfMeasureClick(value) {
    //clear the saved filters and expansion panels list before saving the Unit change.
    this.searchCriteria.removeFilters();
    this.unitOfMeasureService.setUnitOfMeasure(value, true);
    //convert the locally stored loads when UoM is changed.
    this.loadCaseService.convertStoredLoads();
  }

  getCheckedChild() {
    return this.unitOfMeasureService.getUnitOfMeasure();
  }

  updateMenuSelected(selected) {
    localStorage.setItem('MenuSelected', selected);
  }

  getMenuSelected() {
    return localStorage.getItem('MenuSelected');
  }

  logout() {
    this._msalService.logout();
  }
}
