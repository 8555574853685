import { Component } from '@angular/core';
import { LoaderService } from '../../../../service/tcdb/loader.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  loading: boolean;

  constructor(private _loaderService: LoaderService) {
    this._loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }
}
