import { ICandidateConnectionResult } from "../../models/responses/interfaces/icandidateConnectionResult.interface";

export class MockCandidateConnectionResult {
  getMockCandidateConnection(): ICandidateConnectionResult[] {
    return this.mockCandidateConnection;
  }

  private mockCandidateConnection: ICandidateConnectionResult[] = [
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1600",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0018",
      "od": 2.375,
      "yield": 80,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo":""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1601",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0019",
      "od": 2.375,
      "yield": 90,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1602",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0020",
      "od": 2.375,
      "yield": 95,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1627",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0015",
      "od": 2.375,
      "yield": 80,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1628",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0016",
      "od": 2.375,
      "yield": 90,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "T95, C95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1629",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0017",
      "od": 2.375,
      "yield": 95,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-8",
      "connectionTypeString": "Integral Joint",
      "weight": 4.7,
      "gradesReportString": "P110, C110",
      "gasEnvelopePresence": "Present",
      "connectionId": "1464",
      "fullyEvaluated": "No",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "N/A For FEA Only",
      "document": "CES-2006-0396",
      "od": 2.375,
      "yield": 110,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-8",
      "connectionTypeString": "Integral Joint",
      "weight": 4.7,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1069",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2005-0194",
      "od": 2.375,
      "yield": 80,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-8",
      "connectionTypeString": "Integral Joint",
      "weight": 4.7,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1070",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2005-0195",
      "od": 2.375,
      "yield": 90,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-8",
      "connectionTypeString": "Integral Joint",
      "weight": 4.7,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1071",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2005-0196",
      "od": 2.375,
      "yield": 95,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-8",
      "connectionTypeString": "Integral Joint",
      "weight": 3.7,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1072",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2005-0190",
      "od": 2.375,
      "yield": 80,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-8",
      "connectionTypeString": "Integral Joint",
      "weight": 4.7,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1073",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2005-0191",
      "od": 2.375,
      "yield": 90,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-8",
      "connectionTypeString": "Integral Joint",
      "weight": 4.7,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1074",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2005-0185",
      "od": 2.375,
      "yield": 95,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1075",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0001",
      "od": 2.375,
      "yield": 80,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1076",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0002",
      "od": 2.375,
      "yield": 90,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1077",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0003",
      "od": 2.375,
      "yield": 95,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1078",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0004",
      "od": 2.375,
      "yield": 80,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1079",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0005",
      "od": 2.375,
      "yield": 90,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1080",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0006",
      "od": 2.375,
      "yield": 95,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Not Present",
      "connectionId": "2065",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 4010 NM",
      "document": "CES-20xx-0xxx",
      "od": 2.375,
      "yield": 80,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Not Present",
      "connectionId": "2066",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 4010 NM",
      "document": "CES-20xx-0xxx",
      "od": 2.375,
      "yield": 95,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 4.6,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Not Present",
      "connectionId": "2094",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 4010 NM",
      "document": "CES-20xx-0xxx",
      "od": 2.375,
      "yield": 90,
      "wallThickness": 0.19,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "TenarisHydril",
      "connectionName": "563",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1598",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0013",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1642",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0039",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1643",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0040",
      "od": 2.875,
      "yield": 90,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "T95, C95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1644",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0041",
      "od": 2.875,
      "yield": 95,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1645",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0042",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1646",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0043",
      "od": 2.875,
      "yield": 90,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1647",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0044",
      "od": 2.875,
      "yield": 95,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-6",
      "connectionTypeString": "Integral Joint",
      "weight": 8.7,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1548",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2009-0037",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-6",
      "connectionTypeString": "Integral Joint",
      "weight": 8.7,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1549",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2009-0038",
      "od": 2.875,
      "yield": 90,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-6",
      "connectionTypeString": "Integral Joint",
      "weight": 8.7,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1550",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2009-0039",
      "od": 2.875,
      "yield": 95,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-6",
      "connectionTypeString": "Integral Joint",
      "weight": 8.7,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1551",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2009-0040",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-6",
      "connectionTypeString": "Integral Joint",
      "weight": 8.7,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1552",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2009-0041",
      "od": 2.875,
      "yield": 90,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Benoit",
      "connectionName": "BTS-6",
      "connectionTypeString": "Integral Joint",
      "weight": 8.7,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1553",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 72733",
      "document": "CES-2009-0042",
      "od": 2.875,
      "yield": 95,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1105",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0031",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1106",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0032",
      "od": 2.875,
      "yield": 90,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1107",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0033",
      "od": 2.875,
      "yield": 95,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1108",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0034",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1109",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0035",
      "od": 2.875,
      "yield": 90,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1110",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Shell HP Type III or similar API Modified",
      "document": "CES-2006-0036",
      "od": 2.875,
      "yield": 95,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Not Present",
      "connectionId": "2055",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 4010 NM",
      "document": "CES-20xx-0xxx",
      "od": 2.875,
      "yield": 80,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Not Present",
      "connectionId": "2056",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 4010 NM",
      "document": "CES-20xx-0xxx",
      "od": 2.875,
      "yield": 95,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 8.6,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Not Present",
      "connectionId": "2089",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "Bestolife 4010 NM",
      "document": "CES-20xx-0xxx",
      "od": 2.875,
      "yield": 90,
      "wallThickness": 0.308,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Tenaris/Hydril",
      "connectionName": "3SB",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "L80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1502",
      "fullyEvaluated": "No",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "N/A For FEA Only",
      "document": "CES-2008-0020",
      "od": 5.5,
      "yield": 80,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1618",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0156",
      "od": 5.5,
      "yield": 80,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1619",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0157",
      "od": 5.5,
      "yield": 90,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1620",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0158",
      "od": 5.5,
      "yield": 95,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1747",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0153",
      "od": 5.5,
      "yield": 80,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "1748",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0154",
      "od": 5.5,
      "yield": 90,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "JFE",
      "connectionName": "BEAR",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "T95, C95",
      "gasEnvelopePresence": "Present",
      "connectionId": "1749",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2011-0155",
      "od": 5.5,
      "yield": 95,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "NKK",
      "connectionName": "NK3SB",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "890",
      "fullyEvaluated": "No",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "N/A for FEA only",
      "document": "CES-2004-0017",
      "od": 5.5,
      "yield": 80,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "NKK",
      "connectionName": "NK3SB",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Present",
      "connectionId": "891",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified",
      "document": "CES-2004-0018",
      "od": 5.5,
      "yield": 95,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "SM-2535-110",
      "gasEnvelopePresence": "Present",
      "connectionId": "1507",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified (Shell HP Type 3)",
      "document": "R024191",
      "od": 5.5,
      "yield": 110,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "TOP",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "SM-2535-125",
      "gasEnvelopePresence": "Present",
      "connectionId": "1512",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified (Shell HP Type 3)",
      "document": "CES-2009-0010",
      "od": 5.5,
      "yield": 125,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "L80",
      "gasEnvelopePresence": "Present",
      "connectionId": "1492",
      "fullyEvaluated": "No",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified\n(BOL 72732 or similar)",
      "document": "CES-2008-0013",
      "od": 5.5,
      "yield": 80,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "P110, C110",
      "gasEnvelopePresence": "Present",
      "connectionId": "626",
      "fullyEvaluated": "No",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": null,
      "document": "CES-2002-043",
      "od": 5.5,
      "yield": 110,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "L80, N80",
      "gasEnvelopePresence": "Present",
      "connectionId": "707",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified\n(BOL 72732 or similar)",
      "document": "CES-2003-0066",
      "od": 5.5,
      "yield": 80,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "C90",
      "gasEnvelopePresence": "Present",
      "connectionId": "708",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified\n(BOL 72732 or similar)",
      "document": "CES-2003-0068",
      "od": 5.5,
      "yield": 45,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "C95, T95",
      "gasEnvelopePresence": "Present",
      "connectionId": "709",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified\n(BOL 72732 or similar)",
      "document": "CES-2003-0070",
      "od": 5.5,
      "yield": 15,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "13Cr-80",
      "gasEnvelopePresence": "Present",
      "connectionId": "710",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified\n(BOL 72732 or similar)",
      "document": "CES-2003-0067",
      "od": 5.5,
      "yield": 35,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "13Cr-90",
      "gasEnvelopePresence": "Present",
      "connectionId": "711",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified\n(BOL 72732 or similar)",
      "document": "CES-2003-0069",
      "od": 5.5,
      "yield": 90,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    },
    {
      "manufacturer": "Vam",
      "connectionName": "Top HC",
      "connectionTypeString": "Threaded And Coupled",
      "weight": 23,
      "gradesReportString": "13Cr-95",
      "gasEnvelopePresence": "Present",
      "connectionId": "712",
      "fullyEvaluated": "Yes",
      "active": "Yes",
      "isHighTorque": "Yes",
      "threadCompound": "API Modified\n(BOL 72732 or similar)",
      "document": "CES-2003-0071",
      "od": 5.5,
      "yield": 25,
      "wallThickness": 0.4149999999999999,
      "odUOM": "in",
      "weightUOM": "lbm/ft",
      "productLine": "",
      "manufacturerLogo": ""
    }
  ];
}

