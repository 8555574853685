<div *ngFor="let sheet of sheetList" style="display:none;">
  <table [id]="getSheetId(sheet)">
    <tr>
      <th *ngFor="let welldet of sheet.wellDetailList">{{welldet}}</th>
    </tr>
    <tr>
      <th *ngFor="let header of sheet.headerList" >{{header}}</th>
    </tr>
    <tr *ngFor="let row of sheet.rows">
      <td *ngFor="let column of row.columns">{{column.content}}</td>
    </tr>
  </table>
</div>
