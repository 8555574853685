import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent {
  @Input() showConfirmationModal = false;
  @Input() confirmationText = '';
  @Input() confirmationTitle = 'Confirm';
  @Input() confirmButtonText = 'Delete';
  @Output() emitResult = new EventEmitter<boolean>();
  constructor() { }

  closeConfirmationModal() {
    this.emitResult.emit(false);
  }

  emitConfirmation() {
    this.emitResult.emit(true);
  }

}
