import { Component } from '@angular/core';
import { MsalService } from "@azure/msal-angular";


@Component({
  selector: 'app-home',
  templateUrl: './home-app.component.html',
  styleUrls: ['./home-app.component.css']
})
export class HomeAppComponent {
  _userFullName: string;
  _userPrincipalName: string;
  fileContent: string | ArrayBuffer = '';

  GraphService;
  constructor(private _msalService: MsalService) {
    const account = this._msalService.instance.getAllAccounts()[0];
    this._userFullName = account.name;
    this._userPrincipalName = account.username;
  }

  onFileSelected(event) {

    const file: File = event.target.files[0];

    if (file) {

      const formData = new FormData();

      formData.append(file.name, file);

      const fileReader: FileReader = new FileReader();
      const self = this;
      fileReader.onloadend = () => { self.fileContent = fileReader.result; };
      fileReader.readAsText(file);
    }
  }

}

