<span class="set_min_body_size">
  <font size="5"><strong>About TCDB App</strong></font><br />
  <strong title="Defined in 'environments.ts">Angular Environment: {{ _environment }}</strong><br />
  <strong title="Defined in 'assets/config/app-config.json'">Client Auth Environment: {{ _clientEnvironment }}</strong><br />
  <strong>Api Version: {{ _version }}</strong><br />
  <strong title="Returned by back-end API endpoint">Api Environment: {{ _apiEnvironment }}</strong><br /><br />
  <strong>User: {{ _userFullName }}</strong><br />
  <strong>UPN: {{ _userPrincipalName }}</strong><br />
</span>


