import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPoints } from '../app-config/endpoints';
import { Observable } from 'rxjs';
import { MeasuresEvent } from 'src/app/common/models/MeasuresEvent';

@Injectable({ providedIn: 'root' })
export class MeasureService {

  constructor(private http: HttpClient) {
    
  }

  logUsage(measuresEventValue: string): Observable<any> {

    const measuresEvent = new MeasuresEvent();
    measuresEvent.feature = measuresEventValue;

    const headers = { 'content-type': 'application/json' }
    let endpoint = `${EndPoints.API_BASE_URL}/Measures/LogUsage`;
    return this.http
      .post<any>(endpoint, measuresEvent, { 'headers': headers });
  }
}
