import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITableHeader } from '../../../../common/models/components/iTableHeader';
import { IColumn, ITableRow } from '../../../../common/models/components/iTableRow';
import { isNumeric, roundTo, sleep } from '../../../../common/utilities/utilities';
import { UnitOfMeasureService } from '../../../../service/tcdb/unitofmeasure.service';

@Component({
  selector: 'app-display-table',
  templateUrl: './display-table.component.html',
  styleUrls: ['./display-table.component.css']
})
export class DisplayTableComponent {
  @Input() tableHeaderList: ITableHeader[] = [];
  @Input() tableRowList: ITableRow[] = [];
  @Input() addRow: boolean = false;
  @Output() addRowEvent = new EventEmitter();
  @Output() deleteRowEvent = new EventEmitter<number>();
  @Output() sortByEvent = new EventEmitter<ITableHeader>();
  sortedByHeader: ITableHeader = { isNumeric: false, name: '', errorMessage: '' };
  showAddRowInput: boolean = false;
  isToShowCopy: boolean = false;
  indexToAddRow: number = 0;

  constructor(private _unitOfMeasure: UnitOfMeasureService) {
    this.showAddRowInput = false;
  }

  updateRow(value, column: IColumn, index: number) {
    let header = this.tableHeaderList.find(p => p.name.startsWith(column.headerName));

    if (header.isNumeric && !isNumeric(value)) {
      column.errorMessage = "It's not numeric";
      return;
    }

    if (!value) {
      column.errorMessage = "The value can't be empty";
      return;
    }

    column.errorMessage = '';

    //Update the value
    if (this.tableRowList[index].tdColumn.filter(p => p.headerName == column.headerName).length > 0) {
      header.errorMessage = '';
      let valueToBeAdded = value;
      if (header.name.startsWith('Pressure')) {
        if (this._unitOfMeasure.getUnitOfMeasure() == 'US')
          valueToBeAdded = roundTo(value, 0);
        else
          valueToBeAdded = roundTo(value, 1);
      }
      else if (header.name.startsWith('Tension')) {
        valueToBeAdded = roundTo(value, 1);
      }
      column.value = valueToBeAdded;
    }
    column.editable = false;

    // 0 count as filled
    var countValuesFilled = this.tableRowList[index].tdColumn.filter(p => p.value || p.value === 0).length;
    //Push to the parent, since all the fields are filled
    if (countValuesFilled === this.tableHeaderList.length) {
      this.addRowEvent.emit();
      this.showAddRowInput = false;
    }
  }

  async addRowVisible(element) {
    let columnList: IColumn[] = [];
    this.tableHeaderList.forEach((header) => {
      columnList.push({ headerName: header.name, value: '', editable: true, errorMessage: '' });
    });
    this.indexToAddRow = this.tableRowList.length;
    this.tableRowList.push({ colspan: 1, tdColumn: columnList, editable: true });
    this.showAddRowInput = true;
    //Workaround to wait the angular change the element(create the input) and then scroll
    await sleep(100);
    this.scrollToElement(element);
  }

  getElementId(headerName: string) {
    return 'AddRow-' + headerName;
  }

  updateEditableColumn(column: IColumn) {
    column.editable = true;
  }

  deleteRow(index) {
    this.deleteRowEvent.emit(index);
    if (this.showAddRowInput) {
      this.showAddRowInput = false;
    }
  }

  getInputType(column: IColumn) {
    let header = this.tableHeaderList.find(p => p.name.startsWith(column.headerName));
    if (header.isNumeric) {
      return "number";
    }
    return "text";
  }

  onSortByChange(header: ITableHeader) {
    this.sortedByHeader = header;
    if (header.isAscending == null) {
      header.isAscending = false;
    }
    header.isAscending = !header.isAscending;
    this.sortByEvent.emit(header);
  }

  copyClipboard(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    let row_data = pastedText.split("\n");

    // Create table dataSource
    row_data.forEach(row_data => {
      let columnValues = row_data.split("\t");

      if (isNumeric(columnValues[0].toString()) && isNumeric(columnValues[1].toString())) {
        let tension: IColumn = { editable: false, headerName: 'Tension', value: +columnValues[0], errorMessage: '' };
        let pressure: IColumn = { editable: false, headerName: 'Pressure', value: +columnValues[1], errorMessage: '' };
        let tableRow: ITableRow = { tdColumn: [tension, pressure], editable: true, colspan: 1 };
        this.tableRowList.push(tableRow);
      }
    });

    this.addRowEvent.emit();
    this.isToShowCopy = false;
    if (this.showAddRowInput)
      this.deleteRow(this.indexToAddRow);
    this.addRow = true;
  }

  showCopy() {
    this.isToShowCopy = !this.isToShowCopy;
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }
}
