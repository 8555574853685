import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TabStorageService } from './tabstorage.service';
@Injectable({ providedIn: 'root' })
export class MainHeaderService {
  private loadsCountObj: number = 0;
  private countOfLoads: BehaviorSubject<number>;
  private tabSpecificLoadsCountKey = 'LoadsCountKey_' + this.tabStorageService.getTabId();
  constructor(private tabStorageService: TabStorageService) {
    this.countOfLoads = new BehaviorSubject<number>(this.loadsCountObj);
  }
  public GetLoadCount(): Observable<number>{
    if (this.loadsCountObj == 0) {
      this.loadsCountObj = +sessionStorage.getItem(this.tabSpecificLoadsCountKey);
      this.countOfLoads.next(this.loadsCountObj);
    }
    return this.countOfLoads;
  }
  public UpdateLoadCount(count) {
    this.loadsCountObj = count;
    this.countOfLoads.next(this.loadsCountObj);
    sessionStorage.setItem(this.tabSpecificLoadsCountKey, count);
  }
}
