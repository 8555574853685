<div class="em-l em-l--two-column-fixed">
  <div class="em-l__secondary">
    <header class="em-c-header em-js-header em-c-header--vertical"
            role="banner">
      <div class="em-c-header__inner">
        <div class="em-c-header__nav-container em-js-nav-panel">
          <!-- end em-c-search -->
          <div class="em-c-solid-nav">
            <ul class="em-c-solid-nav__list">
              <li class="em-c-solid-nav__item">
                <input type="file" class="file-input" accept=".xml,.json,.txt" (change)="onFileSelected($event)" #fileUpload>
                <a href="javascript:" (click)="fileUpload.click()" class="em-c-solid-nav__link">
                  <svg class="em-c-icon em-c-icon--small em-c-solid-nav__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../images/em-icons.svg#download"></use>
                  </svg>
                  Import
                </a>
                <!-- end em-c-solid-nav__link -->
              </li>
              <li class="em-c-solid-nav__item">
                <a href="#" class="em-c-solid-nav__link">
                  <svg class="em-c-icon em-c-icon--small em-c-solid-nav__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../images/em-icons.svg#circle-question-mark-filled"></use>
                  </svg>
                  Export
                </a>
                <!-- end em-c-solid-nav__link -->
              </li>
            </ul>
            <!-- end em-c-solid-nav__list -->
          </div>
        </div>
        <!-- end em-c-header__nav -->
      </div>
      <div>
        <app-section></app-section>
      </div>
    </header>
  </div>
  <div class="em-l__main em-u-padding-top-double">
    <main role="main">
      <div>
        <h3>Imported File's Content</h3>
        <pre> {{fileContent}} </pre>
      </div>
      <!-- em-l-container -->
    </main>

    <!-- end em-c-footer -->
  </div>
  <!-- end em-l__main -->
</div>
<!-- end em-l--two-column -->
