<div class="em-c-toolbar">
  <div style="width:100%">
    <div class="em-l-grid em-l-grid--3up">
      <div class="em-u-min-width-20 em-l-grid__item">
        <div class="fpo-block">
          <div class="em-c-field em-c-field--inline em-c-field--small">
            <label for="" class="em-c-field__label ">Sort By</label>
            <div class="em-c-field__body">
              <select class="em-c-select em-c-select" (change)="onSortByChange($event.target.value)">
                <option *ngFor="let sortby of sortbylist" [value]="sortby.value">{{sortby.display}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="em-l-grid__item align-end">
        <div class="em-c-field em-c-field--inline em-c-field--small">
          <label for="" class="em-c-field__label">View</label>
          <div class="em-c-field__body">
            <select class="em-c-select em-c-select" (change)="onViewChange($event.target.value)">
              <option *ngFor="let view of viewList" [value]="view">{{view}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="em-l-grid__item">
        <app-pagination [numPages]="numberOfPages" [currentPage]="currentPage" (pageClick)="pageClick($event)"></app-pagination>
      </div>
    </div>

    <div class="fpo-block" style="padding:10px;">
      <div class="em-c-field em-c-field--inline em-c-field--small">
        <app-filter-chip [filterChipItems]="filterChipItems" (filterChipChanged)="closeFilterFilterChip($event)"></app-filter-chip>
        <div class="em-c-field__body">
          <button class="em-c-btn" (click)="resetFilters()" *ngIf="filterChipItems.length > 0" data-testid="resetfilter">
            <span class="em-c-btn__text">Reset</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
