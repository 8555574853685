<div style="display:none;">
  <div id="htmlData">
    <p style="text-align:right;">User: {{userName}}</p>
    <h2 style="text-align:center;">Candidate Connections</h2>
    <p><b>Selection Criteria:</b></p>

    <p>
      {{selectionCriteria}}
    </p>

    <table data-pdfmake="{&quot;layout&quot;:&quot;headerLineOnly&quot;}">
      <tr>
        <th>Manufacturer</th>
        <th>Connection Name</th>
        <th>Connection Type</th>
        <th>Weight ({{getWeightUOM()}})</th>
        <th>Grades</th>
        <th>Gas Envelope</th>
        <th>Product Line</th>
        <th>Connection Id</th>
      </tr>
      <tr *ngFor="let candidateConnection of candidateConnectionResultsWithEnvelope">
        <td>{{candidateConnection.manufacturer}}</td>
        <td>{{candidateConnection.connectionName}}</td>
        <td>{{candidateConnection.connectionTypeString}}</td>
        <td>{{candidateConnection.weight}}</td>
        <td>{{candidateConnection.gradesReportString}}</td>
        <td>{{candidateConnection.gasEnvelopePresence}}</td>
        <td>{{candidateConnection.productLine}}</td>
        <td>{{candidateConnection.connectionId}}</td>
      </tr>
    </table>
  </div>
</div>
