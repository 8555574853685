import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertErrorComponent } from '../tcdb/components/alert-error/alert-error.component';
import { ExportToExcelComponent } from '../tcdb/components/export-to-excel/export-to-excel.component';

@NgModule({
  declarations: [AlertErrorComponent, ExportToExcelComponent],
  imports: [
    CommonModule
  ],
  exports: [AlertErrorComponent, ExportToExcelComponent]
})
export class SharedModule { }
