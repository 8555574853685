<section class="em-c-section em-c-section--expandable" [ngClass]="isClosed || forceCollapse ? 'em-is-closed' : ''">
  <header class="em-c-section__header" *ngIf="!expansionPanel.icon">
    <label [ngClass]="expansionPanel.isDisabled ? 'gray-color' : ''">
      <input data-testid="expansion-panel-parent-checkbox" type="checkbox" [attr.disabled]="expansionPanel.isDisabled ? true : null"
             [id]="expansionPanel.title"
             name="{{expansionPanel.title}}"
             value="{{expansionPanel.title}}"
             class="em-c-input-group__control accent-black-color"
             (change)="onParentCheckboxChange($event)"
             [checked]="expansionPanel.expansionCheckboxChecked" />
      {{expansionPanel.title}}
    </label>
    <svg class="em-c-icon em-c-icon--medium em-c-section__icon" (click)="onExpansion()">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#caret-down"></use>
    </svg>
  </header>

  <div class="em-c-section__body em-js-section-target">
    <div *ngFor="let item of expansionPanel.items" class="em-c-dropdown-check__item">
      <label class="child-checkbox" [ngClass]="item.isDisabled ? 'gray-color' : ''">
        {{expansionPanel.items.indexOf(item)+1}}
        <input *ngIf="expansionPanel.usecheckbox" data-testid="expansion-panelcheckbox-charts" type="checkbox" [ngStyle]="{'accent-color':item.color}" [attr.disabled]="item.isDisabled ? true : null"
               [id]="getElementId(item.value)"
               name="{{expansionPanel.title}}.{{item.value}}"
               value="{{item.value}}"
               class="em-c-input-group__control"
               (change)="onCheckboxChange($event,item.value)"
               [checked]="item.checked" />
        {{item.name}}
      </label>
    </div>
  </div>
  <!-- end em-c-section__body -->

</section>
<!-- end em-c-section -->
