import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert-success',
  templateUrl: './alert-success.component.html',
  styleUrls: ['./alert-success.component.css']
})
export class AlertSuccessComponent {
  @Input() message;
  @Output() closeMessageEvent = new EventEmitter();

  closeAlert() {
    this.closeMessageEvent.emit();
  }

  showAlert() {
    return this.message === '' ? false : true;
  }
}

