import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getUserPhoto(): Observable<SafeUrl> {
    const requestUrl = `https://graph.microsoft.com/beta/me/photo/$value`;
    return this.http.get(requestUrl, { responseType: 'blob' }).pipe(map(result => {
      const url = window.URL;
      return this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(result));
    }));
  }
}
