<header class="em-c-header em-c-header--red" role="banner">
  <div class="em-c-header__inner slim-container">
    <div class="em-c-header__body">
      <div class="em-c-header__title-container em-u-padding-left">
        <div class="home-header cold-lg-4">
          <img src="assets/images/EM_logo.png"
               style="height: 32px; margin-top: 10px;"
               alt="ExxonMobil" />
        </div>

        <div>
          <h2 class="em-c-header__title">
            <a class="em-c-header__title-link">Tubular Connections Database</a>
          </h2>
        </div>
      </div>
      
      <!--This class will align elements horizantally-->
      <div class="em-c-header__nav-container em-js-nav-panel smaller-font em-u-text-align-right em-u-padding-right">
        <div>
          <nav id="nav" class="em-c-primary-nav" role="navigation">
            <ul class="em-c-primary-nav__list">
              <li class="em-c-primary-nav__item ">
                <a href="search" class="em-c-primary-nav__link" [ngClass]="{'em-is-current': getMenuSelected() === 'search'}" (click)="updateMenuSelected('search')">
                  Search
                </a>
                <!--end em-c-primary-nav__link-->
              </li>
              <li class="em-c-primary-nav__item">
                <a href="load" class="em-c-primary-nav__link" [ngClass]="{'em-is-current': getMenuSelected() === 'load'}" (click)="updateMenuSelected('load')">
                  Loads <span class="em-c-badge" data-testid="countOfLoads">{{ countOfLoads }}</span>
                </a>
                <!--end em-c-primary-nav__link-->
              </li>
              <li class="em-c-primary-nav__item">
                <app-expandable-menu [childrenList]="unitOfMeasureList" [title]="unitOfMeasureTitle" (childAction)="onUnitOfMeasureClick($event)" [checkedChild]="getCheckedChild()"></app-expandable-menu>
              </li>
              <li class="em-c-primary-nav__item">
                <app-expandable-menu [childrenList]="helpList" [title]="helpTitle" ></app-expandable-menu>
              </li>
            </ul>
            <!-- end em-c-nav__list -->
          </nav>
        </div>
        <div class="em-u-padding-left">
          {{ userName }}<br />
          <a (click)="logout()" style="color: white;cursor: pointer">Log out</a>
        </div>
      </div>
    </div>
    </div>
</header>
