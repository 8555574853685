import { Component, Input } from '@angular/core';
import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/icandidateConnectionResult.interface';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-candidate-export-pdf',
  templateUrl: './candidate-export-pdf.component.html',
  styleUrls: ['./candidate-export-pdf.component.css']
})
export class CandidateExportPdfComponent {
  @Input() selectionCriteria = '';
  @Input() candidateConnectionResultsWithEnvelope: ICandidateConnectionResult[] = [];
  userName = '';

  constructor(private _msalService: MsalService) {
    const account = this._msalService.instance.getAllAccounts()[0];
    this.userName = account.name;
  }

  getWeightUOM() {
    if (this.candidateConnectionResultsWithEnvelope.length > 0) {
      return this.candidateConnectionResultsWithEnvelope[0].weightUOM;
    }
    return 'lbm/ft'
  }
}
