<div class="em-js" id="div_modal_start_confirmation" *ngIf="showConfirmationModal">
  <div class="em-c-modal" id="confirmationModal" [ngClass]="!showConfirmationModal ? 'em-is-closed' : ''">
    <div class="em-c-modal__window">
      <div class="em-c-modal__header header-color">
        <h3 class="em-c-modal__titler">{{confirmationTitle}}</h3>
        <button class="em-c-btn em-c-btn--bare em-c-modal__close-btn  header-color" (click)="closeConfirmationModal()">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="../../../../../assets/vendor/unity/images/em-icons.svg#x-filled">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#x-filled"></use>
          </svg>
        </button>
      </div>
      <div class="em-c-modal__body">
        <div class="em-c-field ">
          <label for="" class="em-c-field__label">{{confirmationText}}</label>
          <div class="em-c-btn-group em-c-modal--alert em-js-modal-only">
            <button data-testid="deleteConfirmationButton" class="em-c-btn em-c-btn--primary em-js-modal-confirm-trigger" (click)="emitConfirmation()">
              <span class="em-c-btn__text">{{confirmButtonText}}</span>
            </button>
            <button class="em-c-btn em-c-btn--secondary em-js-modal-cancel-trigger" (click)="closeConfirmationModal()">
              <span class="em-c-btn__text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
