import { EndPoints } from './../app-config/endpoints';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UnitOfMeasureService } from './unitofmeasure.service';
import { SearchCriteriaRequest } from '../../common/models/requests/searchcriteriarequest';
import { ITedIntegrationResponse } from '../../common/models/responses/interfaces/iTedIntergrationResponse';

@Injectable({ providedIn: 'root' })
export class TedIntegrationService {

  public headers = { 'content-type': 'application/json' };
  public _unitOfMeasureService: UnitOfMeasureService;

  constructor(private http: HttpClient, unitOfMeasure: UnitOfMeasureService) {
    this._unitOfMeasureService = unitOfMeasure;
  }

  async getTedIntegrationInfo(id): Promise<ITedIntegrationResponse> {
    const headers = { 'content-type': 'application/json' }
    let endpoint = `${EndPoints.API_BASE_URL}/tedintegration/getintegrationinfo/${id}`;
    let body = '[]';
    return await this.http.post<ITedIntegrationResponse>(endpoint, body, { 'headers': headers }).toPromise();
  }

}
