import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CollapseService {
  public collapse = new BehaviorSubject(false);

  constructor() {
  }

  public publishCollapse() {
    this.collapse.next(true);
  }
}
