import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs';
import { Constants } from '../../../../common/constants/constant';
import { IFilterChipItem } from '../../../../common/models/components/iFilterChipItem';

@Component({
  selector: 'app-filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.css']
})
export class FilterChipComponent {
  @Output() filterChipChanged = new EventEmitter<IFilterChipItem>();
  @Input() filterChipItems: IFilterChipItem[] = [];

  constructor() { }

  closeFilter(filterChipItem: IFilterChipItem) {
    this.filterChipChanged.emit(filterChipItem);
  }

  getNameToShow(filterChipItem: IFilterChipItem) {
    if (filterChipItem.parentName && filterChipItem.mainFilterName != Constants.sizeTitle && !filterChipItem.allChildrenChecked) {
      return filterChipItem.parentName + " | " + filterChipItem.name;
    }
    return filterChipItem.name;
  }


}
