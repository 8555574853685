import { EndPoints } from './../app-config/endpoints';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ICandidateConnectionResult } from '../../common/models/responses/interfaces/iCandidateConnectionResult.interface'
import { ISearchCriteriaRequest } from '../../common/models/requests/interfaces/isearchcriteriarequest';
import { MockCandidateConnectionResult } from '../../common/mocks/response/mockCandidateConnectionResult';
import { UnitOfMeasureService } from './unitofmeasure.service';
import { Observable } from 'rxjs';
import { section } from '../../common/models/section.model';
import { TabStorageService } from './tabstorage.service';
import { IExpansionPanelModel } from '../../common/models/components/expansionpanelmodel.interface';

@Injectable({ providedIn: 'root' })
export class SearchCriteriaService {
  public error: string;
  public endpoint: string;
  tabSpecificKeyFilter: string;
  tabSpecificKeyExpansion: string;

  constructor(private http: HttpClient, private unitOfMeasure: UnitOfMeasureService, private tabStorageService: TabStorageService) {
    this.endpoint = `${EndPoints.API_BASE_URL}/SearchCriteria/`;
    //generates unique browser tab specific id for filters and expansion panel list
    this.tabSpecificKeyFilter = 'SearchFiltersToSave_' + this.tabStorageService.getTabId();
    this.tabSpecificKeyExpansion = 'ExpansionPanelListToSave_' + this.tabStorageService.getTabId();
  }

  search(searchCriteriaRequest: ISearchCriteriaRequest) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(searchCriteriaRequest);
    let endpoint = `${this.endpoint}CandidateConnections`;
    return this.http
      .post<ICandidateConnectionResult[]>(endpoint, body, { 'headers': headers });
  }

  async searchWithGasEnvelope(connectionIdList: string[]): Promise<ICandidateConnectionResult[]> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(connectionIdList);
    let endpoint = `${this.endpoint}CandidateConnectionsForPDFAsync/${this.unitOfMeasure.getUnitOfMeasure()}`;
    return await this.http
      .post<ICandidateConnectionResult[]>(endpoint, body, { 'headers': headers }).toPromise();
  }

  searchWithAdditionalInfo(connectionIdList: string[]) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(connectionIdList);
    let endpoint = `${this.endpoint}CandidateConnectionsWithAdditionalInfo/${this.unitOfMeasure.getUnitOfMeasure()}`;
    return this.http
      .post<ICandidateConnectionResult[]>(endpoint, body, { 'headers': headers });    
  }

  generateExcelByCandidateConnection(connectionId, sections: section[]): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    let body = '[]';
    if (sections) {
      body = JSON.stringify(sections);
    }
    let endpoint = `${this.endpoint}DownloadExcelFileByCandidateConnection/${connectionId}/${this.unitOfMeasure.getUnitOfMeasure()}`;
    return this.http.post(endpoint, body, { 'headers': headers, observe: 'response', responseType: 'blob' });
  }

  searchMock(): ICandidateConnectionResult[] {
    return new MockCandidateConnectionResult().getMockCandidateConnection();
  }
  //This method saves the filters and checked expansion panels to the session storage
  public saveFilters(searchCriteriaRequest: ISearchCriteriaRequest, expansionFilters: IExpansionPanelModel[]) {
    sessionStorage.setItem(this.tabSpecificKeyExpansion, JSON.stringify(expansionFilters));
    sessionStorage.setItem(this.tabSpecificKeyFilter, JSON.stringify(searchCriteriaRequest));
  }
  //This method gets the saved filters
  getFilters(): ISearchCriteriaRequest  {
    let filters: ISearchCriteriaRequest = JSON.parse(sessionStorage.getItem(this.tabSpecificKeyFilter));
    return filters;
  }
  //This method checks if we already have the filters stored in the session storage
  public checkFiltersAvailable() {
    if (!sessionStorage.getItem(this.tabSpecificKeyFilter) || sessionStorage.getItem(this.tabSpecificKeyFilter) ==null || sessionStorage.getItem(this.tabSpecificKeyFilter).length == 0)
      return false;
    return true;
  }
  //This method gets the expansion panel list with the checked filters
  getExpansionPanel(): IExpansionPanelModel[] {
    let expansionList: IExpansionPanelModel[] = JSON.parse(sessionStorage.getItem(this.tabSpecificKeyExpansion));
    return expansionList;
  }
  //This method removes filters and expansion panel list saved in session storage
  removeFilters() {
    sessionStorage.removeItem(this.tabSpecificKeyFilter);
    sessionStorage.removeItem(this.tabSpecificKeyExpansion);
  }
  //This method checks if the serach criteria request is valid
  checkValidity(criteria: ISearchCriteriaRequest) { 
    return (typeof(criteria.outerDiameter)=='undefined' || (criteria.outerDiameter.length == 0))
      && (typeof (criteria.urcDocumentNumber) == 'undefined' || (criteria.urcDocumentNumber == null))
      && (typeof (criteria.isCRA) == 'undefined')
      && (typeof (criteria.isCarbonSteel) == 'undefined')
      && (typeof (criteria.isThreadedAndCoupled) == 'undefined')
      && (typeof (criteria.isIntegralJoint) == 'undefined')
      && (typeof (criteria.isSemiFlush) == 'undefined')
      && (typeof (criteria.isFlushJoint) == 'undefined')
      && (typeof (criteria.connectionName) == 'undefined' || (criteria.connectionName == null))
      && (typeof (criteria.manufacturer) == 'undefined' || (criteria.manufacturer == null))
      && (typeof (criteria.yield) == 'undefined' || (criteria.yield.length == 0))
      && (typeof (criteria.threadCompoundApplied) == 'undefined' || (criteria.threadCompoundApplied == null))
      && (typeof (criteria.isFullyEvaluated) == 'undefined')
      && (typeof (criteria.connectionUse) == 'undefined' || (criteria.connectionUse == null))
      && (typeof (criteria.evaluation) == 'undefined' || (criteria.evaluation == null))
      && (typeof (criteria.highTorqueOnly) == 'undefined')
      && (typeof (criteria.active) == 'undefined')
      && (typeof (criteria.inactive) == 'undefined')

  }
}
